.vogue {
    background-color: #607d8b !important
}

.vogue-text {
    color: #607d8b !important
}

.vogue.lighten-5 {
    background-color: rgb(236, 239, 241) !important;
}

.vogue-text.text-lighten-5 {
    color: #eceff1 !important
}

.vogue.lighten-4 {
    background-color: #cfd8dc !important
}

.vogue-text.text-lighten-4 {
    color: #cfd8dc !important
}

.vogue.lighten-3 {
    background-color: #b0bec5 !important
}

.vogue-text.text-lighten-3 {
    color: #b0bec5 !important
}

.vogue.lighten-2 {
    background-color: #90a4ae !important
}

.vogue-text.text-lighten-2 {
    color: #90a4ae !important
}

.vogue.lighten-1 {
    background-color: #78909c !important
}

.vogue-text.text-lighten-1 {
    color: #68517b !important
}

.vogue.darken-1 {
    background-color: #546e7a !important
}

.vogue-text.text-darken-1 {
    color: #546e7a !important
}

.vogue.darken-2 {
    background-color: #455a64 !important
}

.vogue-text.text-darken-2 {
    color: #455a64 !important
}

.vogue.darken-3 {
    background-color: #68517b !important
}

.vogue.darken-3:hover {
    background-color: #443352 !important
}

.vogue-text.text-darken-3 {
    color: #37474f !important
}

.vogue.darken-4 {
    background-color: #1a002f !important
}

.vogue-text.text-darken-4 {
    color: #263238 !important
}


.vogue-fill {
    fill: #607d8b !important
}

.vogue-fill.fill-lighten-5 {
    fill: #eceff1 !important
}

.vogue-fill.fill-lighten-4 {
    fill: #cfd8dc !important
}

.vogue-fill.fill-lighten-3 {
    fill: #b0bec5 !important
}

.vogue-fill.fill-lighten-2 {
    fill: #90a4ae !important
}

.vogue-fill.fill-lighten-1 {
    fill: #78909c !important
}

.vogue-fill.fill-darken-1 {
    fill: #432b57 !important
}

.vogue-fill.fill-darken-2 {
    fill: #455a64 !important
}

.vogue-fill.fill-darken-3 {
    fill: #37474f !important
}

.vogue-fill.fill-darken-4 {
    fill: #263238 !important
}