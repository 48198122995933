.container {
    max-width: 1400px;
    margin: auto;
}

.contImg {
    width: 100%;
    max-width: 500px;
    border: 1px solid #a9a9a918;
    border-radius: 10px;
    flex: 1 1 30%;

    img {
        width: 100%;
        max-width: 350px;
        cursor: pointer;
    }
}

.cardCont {
    border: 1px solid #a9a9a91f;
    flex: 0 1 30%;
}

.deliveryCont {
    flex: 0 1 30%;
    max-width: 420px;
    margin: auto;

    .delivery {
        border-bottom-style: solid !important;
        border-bottom-width: 5px !important;
        border-bottom-color: #35353521 !important;
        border: 1px solid #a9a9a91f;
        flex: 0 1;
        margin: 0.313rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        border-radius: 10px;
        box-shadow: 0 8px 5px #00000047;
        min-height: 170px;

    }



    h3 {
        padding: 0;
        text-align: center;
        margin: 0.7rem auto;
    }

    p {
        font-size: 0.9rem;
        color: #000000af;
        padding: 0.5rem;
    }
}

.specifications {
    font-size: 0.9rem;
    max-width: 450px;
    width: 100%;
}

@media screen and (max-width:765px) {
    .contImg {
        flex: 1 1 100%;
    }

    .cardCont {
        flex: 1 1 100%;
    }

    .deliveryCont {
        flex: 0 1 100%;
        max-width: 420px;
        margin: 0;

        .delivery {
            border-bottom-style: solid !important;
            border-bottom-width: 5px !important;
            border-bottom-color: #35353521 !important;
            border: 1px solid #a9a9a96c;
            flex: 0 1;
            margin: 0.313rem;
            margin-bottom: 0.3rem;
            padding: 0.25rem;
            border-radius: 10px;
            box-shadow: 0 8px 5px #00000047;
        }

        h3 {
            padding: 0;
        }

        p {
            font-size: 0.9rem;
            color: #000000af;
            padding: 0 1rem 1rem;
        }
    }
}