.uslugCont{
    max-width: 1300px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
}
.usluga {
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    flex-wrap: nowrap;

    img {
        width: 100%;
        max-width: 320px;
        max-height: -webkit-fill-available;
    }
}
.usluga:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 300px;
    background-color: #0000008f;
}
.aboutCont{
    max-width: 900px;
}

@media screen and (max-width:900px){
    .aboutCont{
        // max-width: 520px;
    }
}

@media screen and (max-width:765px) {
    .usluga {
        width: 100%;
        height: 420px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        box-shadow: 0 0 8px 2px rgb(105, 105, 105);
        div{
            padding: 1rem;
            p{
                padding: 1rem;
            }
        }
    
        img {
            display: none;
        }
    }
    
    .usluga:before {
        height: 420px;
    }

}