.teal {
    background-color: #009688 !important
}

.teal-text {
    color: #009688 !important
}

.teal.lighten-6 {
    background-color: #e0f2f100 !important
}

.teal.lighten-5 {
    background-color: #e0f2f1 !important
}

.teal-text.text-lighten-5 {
    color: #e0f2f1 !important
}

.teal.lighten-4 {
    background-color: #b2dfdb !important
}

.teal-text.text-lighten-4 {
    color: #b2dfdb !important
}

.teal.lighten-3 {
    background-color: #80cbc4 !important
}

.teal-text.text-lighten-3 {
    color: #80cbc4 !important
}

.teal.lighten-2 {
    background-color: #4db6ac !important
}

.teal-text.text-lighten-2 {
    color: #4db6ac !important
}

.teal.lighten-1 {
    background-color: #26a69a !important
}

.teal.lighten-1:hover {
    background-color: #129184 !important
}

.teal-text.text-lighten-1 {
    color: #26a69a !important
}

.teal.darken-1 {
    background-color: #406d69 !important
}

.teal-text.text-darken-1 {
    color: #00897b !important
}

.teal.darken-2 {
    background-color: #00796b !important
}

.teal-text.text-darken-2 {
    color: #00796b !important
}

.teal.darken-3 {
    background-color: #00695c !important
}

.teal-text.text-darken-3 {
    color: #00695c !important
}

.teal.darken-4 {
    background-color: #004d40 !important
}

.teal-text.text-darken-4 {
    color: #004d40 !important
}

.teal.accent-1 {
    background-color: #a7ffeb !important
}

.teal-text.text-accent-1 {
    color: #a7ffeb !important
}

.teal.accent-2 {
    background-color: #64ffda !important
}

.teal-text.text-accent-2 {
    color: #64ffda !important
}

.teal.accent-3 {
    background-color: #1de9b6 !important
}

.teal-text.text-accent-3 {
    color: #1de9b6 !important
}

.teal.accent-4 {
    background-color: #00bfa5 !important
}

.teal-text.text-accent-4 {
    color: #00bfa5 !important
}


.teal-fill {
    fill: #009688 !important
}

.teal-fill.fill-lighten-5 {
    fill: #e0f2f1 !important
}

.teal-fill.fill-lighten-4 {
    fill: #b2dfdb !important
}

.teal-fill.fill-lighten-3 {
    fill: #80cbc4 !important
}

.teal-fill.fill-lighten-2 {
    fill: #4db6ac !important
}

.teal-fill.fill-lighten-1 {
    fill: #26a69a !important
}

.teal-fill.fill-darken-1 {
    fill: #00897b !important
}

.teal-fill.fill-darken-2 {
    fill: #00796b !important
}

.teal-fill.fill-darken-3 {
    fill: #00695c !important
}


.teal-fill.fill-darken-4 {
    fill: #004d40 !important
}

.teal-fill.fill-accent-1 {
    fill: #a7ffeb !important
}

.teal-fill.fill-accent-2 {
    fill: #64ffda !important
}

.teal-fill.fill-accent-3 {
    fill: #1de9b6 !important
}

.teal-fill.fill-accent-4 {
    fill: #00bfa5 !important
}
