.seabreezeresort {
    background-color: #624696 !important;
}

.seabreezeresort.lighten-1 {
    background-color: #44bb6e !important
}

.seabreezeresort.lighten-2 {
    background-color: #28a745 !important
}

.seabreezeresort.lighten-3 {
    background-color: #afdc8c !important
}

.seabreezeresort.lighten-4 {
    background-color: #fff !important
}

.seabreezeresort.lighten-5 {
    background-color: #f9fbfb !important
}

.seabreezeresort.darken-1 {
    background-color: #757575 !important
}

.seabreezeresort.darken-2 {
    background-color: #616161 !important
}

.seabreezeresort.darken-3 {
    background-color: rgb(143, 48, 153) !important;
}

.seabreezeresort.darken-3:hover {
    background-color: #c360cd !important;

}

.seabreezeresort.darken-4 {
    background-color: rgba(98, 70, 150, 0.6) !important
}

.seabreezeresort-text {
    color: #434545 !important;
}

.seabreezeresort-text.text-lighten-1 {
    color: #434545 !important;
}

.seabreezeresort-text.text-lighten-2 {
    color: #e0e0e0 !important;
}

.seabreezeresort-text.text-lighten-3 {
    color: #eee !important;
}

.seabreezeresort-text.text-lighten-4 {
    color: #f5f5f5 !important;
}

.seabreezeresort-text.text-lighten-5 {
    color: #fafafa !important;
}

.seabreezeresort-text.text-darken-1 {
    color: #757575 !important;
}


.seabreezeresort-text.text-darken-2 {
    color: #616161 !important;
}

.seabreezeresort-text.text-darken-3 {
    color: #424242 !important;
}


.seabreezeresort-text.text-darken-4 {
    color: #212121 !important;
}

.seabreezeresort-fill {
    fill: #9e9e9e !important
}

.seabreezeresort-fill.fill-lighten-5 {
    fill: #fafafa !important
}

.seabreezeresort-fill.fill-lighten-4 {
    fill: #f5f5f5 !important
}

.seabreezeresort-fill.fill-lighten-3 {
    fill: #eee !important
}

.seabreezeresort-fill.fill-lighten-2 {
    fill: #e0e0e0 !important
}

.seabreezeresort-fill.fill-lighten-1 {
    fill: #bdbdbd !important
}

.seabreezeresort-fill.fill-darken-1 {
    fill: #624696 !important
}

.seabreezeresort-fill.fill-darken-2 {
    fill: #616161 !important
}

.seabreezeresort-fill.fill-darken-3 {
    fill: rgb(143, 48, 153) !important
}

.seabreezeresort-fill.fill-darken-4 {
    fill: #28a745 !important
}