.light-blue {
    background-color: #03a9f4 !important
}

.light-blue-text {
    color: #03a9f4 !important
}

.light-blue.lighten-5 {
    background-color: #e1f5fe !important
}

.light-blue.lighten-6 {
    background-color: #e1f5fe00 !important
}

.light-blue-text.text-lighten-5 {
    color: #e1f5fe !important
}

.light-blue.lighten-4 {
    background-color: #b3e5fc !important
}

.light-blue-text.text-lighten-4 {
    color: #b3e5fc !important
}

.light-blue.lighten-3 {
    background-color: #81d4fa !important
}

.light-blue-text.text-lighten-3 {
    color: #81d4fa !important
}

.light-blue.lighten-2 {
    background-color: #4fc3f7 !important
}

.light-blue-text.text-lighten-2 {
    color: #4fc3f7 !important
}

.light-blue.lighten-1 {
    background-color: #29b6f6 !important
}

.light-blue.lighten-1:hover {
    background-color: #18a1e0 !important
}

.light-blue-text.text-lighten-1 {
    color: #29b6f6 !important
}

.light-blue.darken-1 {
    background-color: #3c81a3 !important
}

.light-blue-text.text-darken-1 {
    color: #039be5 !important
}

.light-blue.darken-2 {
    background-color: #0288d1 !important
}

.light-blue-text.text-darken-2 {
    color: #0288d1 !important
}

.light-blue.darken-3 {
    background-color: #0277bd !important
}

.light-blue-text.text-darken-3 {
    color: #0277bd !important
}

.light-blue.darken-4 {
    background-color: #01579b !important
}

.light-blue-text.text-darken-4 {
    color: #01579b !important
}

.light-blue.accent-1 {
    background-color: #80d8ff !important
}

.light-blue-text.text-accent-1 {
    color: #80d8ff !important
}

.light-blue.accent-2 {
    background-color: #40c4ff !important
}

.light-blue-text.text-accent-2 {
    color: #40c4ff !important
}

.light-blue.accent-3 {
    background-color: #00b0ff !important
}

.light-blue-text.text-accent-3 {
    color: #00b0ff !important
}

.light-blue.accent-4 {
    background-color: #0091ea !important
}

.light-blue-text.text-accent-4 {
    color: #0091ea !important
}


.light-blue-fill {
    fill: #03a9f4 !important
}

.light-blue-fill.fill-lighten-5 {
    fill: #e1f5fe !important
}

.light-blue-fill.fill-lighten-4 {
    fill: #b3e5fc !important
}

.light-blue-fill.fill-lighten-3 {
    fill: #81d4fa !important
}

.light-blue-fill.fill-lighten-2 {
    fill: #4fc3f7 !important
}

.light-blue-fill.fill-lighten-1 {
    fill: #29b6f6 !important
}

.light-blue-fill.fill-darken-1 {
    fill: #039be5 !important
}


.light-blue-fill.fill-darken-2 {
    fill: #0288d1 !important
}

.light-blue-fill.fill-darken-3 {
    fill: #0277bd !important
}


.light-blue-fill.fill-darken-4 {
    fill: #01579b !important
}

.light-blue-fill.fill-accent-1 {
    fill: #80d8ff !important
}


.light-blue-fill.fill-accent-2 {
    fill: #40c4ff !important
}


.light-blue-fill.fill-accent-3 {
    fill: #00b0ff !important
}


.light-blue-fill.fill-accent-4 {
    fill: #0091ea !important
}