.allContainer {
    padding: 5px 15px;
    margin: 0.2rem;
    justify-content: space-evenly;
    border: 1px solid #56555530;
    border-bottom-color: #0000004f;
    border-bottom-style: solid;
    border-bottom-width: 8px;
    border-right-color: #00000069;
    border-right-style: solid;
    border-right-width: 4px;
    box-shadow: 0 6px 10px 5px #a9a9a994;
    border-radius: 35px;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    flex: 0 1 30%;
    min-width: 300px;
    height: 170px;
}

.allContainer:hover {
    box-shadow: 0 0 7px 2px #a9a9a9;
    .elem{
        box-shadow: inset 2px 0px 4px 0px #000000ba;
    }
    .img {
        border-right-width: 2px;
        border-bottom-width: 2px;
        padding: 0.5rem;
        box-shadow: 3px 3px 6px 0px #000000cf;
    }
}

.containerImg {
    display: flex;
    justify-content: space-between;
    align-items: center;
width: calc(100% - 20px);
    .img {
        border-radius: 50%;
        padding: 0.4rem;
        border-right-color: #00000080;
        border-right-style: solid;
        border-right-width: 4px;
        border-bottom-color: #00000080;
        border-bottom-style: solid;
        border-bottom-width: 4px;
        box-shadow: 4px 4px 8px 0px #000000cf;
        max-width: 50px;
        height: 50px;
    }
}

.containerText {
    width: calc(100% - 20px);
    font-size: 1.2rem;
    padding: 0.5rem;
}
.elem{
    right: -5px;
    position: absolute;
    width: 30px;
    height: 50%;
    background-color: #d5c3f1;
    top: 25%;
    border-radius: 20px 0 0 20px;
    box-shadow: inset 2px 0px 10px 0px #000000ba;
    z-index: 2;
}