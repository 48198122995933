* {
    padding: 0;
    margin: 0;
    position: relative;
}

.main-cont {
    height: auto;
    margin: auto;
    width: calc(100% - 1rem);
    max-width: 1170px;
    padding: 0.5rem;

    h2 {
        padding: 15px 0 20px 15px;
    }

    h3 {
        text-align: center;
        padding: 1rem 0.5rem;
    }
}

.wdCont {
    height: auto;
    margin: auto;
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 10px;
    max-width: 1235px;
    position: absolute;

}

.slick-dots li {
    width: 100px;
    height: 100px;
    margin: 0px 5px 20px 5px !important;
    opacity: .65;
    border-radius: 50%;
    transition: .5s;
}

.slick-active {
    opacity: 1 !important;
    transition: .5s;
    border-radius: 50%;
}

.slick-dots li img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.left {
    flex-direction: row;

    .room_descriptionCont__nGJ-1 {
        right: 0;
    }
}

.right {
    flex-direction: row-reverse;

    .room_descriptionCont__nGJ-1 {
        left: 0;
    }
}

.right>.room_descriptionCont__nGJ-1 {
    left: 0;
    position: absolute;
}

.left>.room_descriptionCont__nGJ-1 {
    right: 0;
    position: absolute;
}

.roomSlides {
    margin-bottom: 10px;
}

.breadCrumbsCont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* .slick-dots button {
    font-size: 0;
    padding: 0;
}

.slick-dots li:nth-child(1) button {
    width: 8px;
    height: 8px;
}

.slick-dots li:nth-child(2) button {
    width: 6px;
    height: 6px;
}

.slick-dots li:nth-child(3) button {
    width: 4px;
    height: 4px;
} */

.push {
    list-style: none;
    font-family: "Lucida Sans";
}

.pushs {
    list-style: auto;
    counter-reset: item;
    font-family: "Lucida Sans";
}

.pushs li {
    display: block
}

ol ol,
ol ul,
ul ol,
ul ul {
    padding: 0;
}

.pushs li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    position: absolute;
    width: 25px;
    height: 25px;
    border: 1px solid #000;
    border-radius: 50%;
    background: #cc980a7e;
    left: -20px;
    transition: .3s ease-in-out;
    top: 20px;
    padding-left: 8px;
}

.pushs ol>li:before {
    width: auto;
    left: -30px;
    padding: 0 8px;
}

.pushs ol>li>ol>li:before {
    left: -35px;
}

.pushs ol>li:after {
    position: absolute;
    border-left: 1px dotted #4F5151;
    width: 1px;
    bottom: -12px;
    content: "";
    left: -2 0px;
    top: 48px;
}

.pushs li {
    position: relative;
    padding: 22px 0 0px 20px;
    // color: #D29D25;
    font-variant: small-caps;
    font-weight: bold;
    cursor: pointer;
}

.pushs li:after {
    position: absolute;
    border-left: 1px dotted #4F5151;
    width: 1px;
    bottom: -12px;
    content: "";
    left: -20px;
    top: 48px;
}

.pushs li:hover:before {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .2)
}

.pushs li:last-child:after {
    content: none;
}

.push li {
    position: relative;
    padding: 22px 0 0px 20px;
    // color: #D29D25;
    font-variant: small-caps;
    font-weight: bold;
    cursor: pointer;
}

.push li:before {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #4F5151;
    content: "";
    left: 0;
    transition: .3s ease-in-out;
    top: 27px;
}

.push li:after {
    position: absolute;
    border-left: 1px dotted #4F5151;
    width: 1px;
    bottom: -12px;
    content: "";
    left: 3px;
    top: 48px;
}

.push li:hover:before {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .2)
}

.push li:last-child:after {
    content: none;
}


.tag {
    padding: 10px 20px;
    background-color: rgb(42, 151, 151);
    border: solid #fff 2px;
    border-radius: 6px;
    margin: 25px 10px;
    color: #fff;

}

.tag.active {
    background-color: rgba(52, 218, 218, 0) !important;
    color: #42A5F5;
    border-radius: 4px;
    border: solid #42A5F5 2px;
}

.contPhotoGalary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;

    a {

        img {
            box-shadow: 0 0 7px 4px #0000006e;
            transition: .5s;
            object-fit: cover;
            margin: 10px;
            width: 200px;
            height: 200px;
            border-radius: 10px;
            border: 1px solid #00000000;
        }

        img:hover {
            box-shadow: none;
            transition: .5s;
            border: 1px solid #fff200;
            box-shadow: 0 0 5px 3px #00000093;
        }
    }

}

.contBackGroundItem {
    position: relative;
}


/*theme start*/
* {
    padding: 0;
    margin: 0;
    font-size: 1em;
    font-family: sans-serif;
    transition: 0.5s ease 0s;
}

body {
    /*    width: 100vw;*/
}

section {
    // width: calc(100% - 2rem);
    // padding: 0 1rem;
}

.flexBox {
    display: flex;
    flex-wrap: wrap;
}

.textHiden {
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
}

/* header start */
.header {
    // background-color: #21252e;
    // color: #fff;

}

.header__cont {
    justify-content: space-between;
    align-items: center;
    // padding: 0.5rem 1rem;
}

.header__cont_logo {}

.header__cont_logo img {
    width: 100%;
    max-width: 280px
}

.header__cont_menu {
    width: 100%;
}

.header__cont_menu a {
    text-decoration: none;
    // color: rgba(255, 255, 255, .4);
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    transition: all 0.5s ease 0s;
    margin: 0.3rem
}

.header__cont_menu a:hover {
    // background-color: #acacac;
    /*    transition: all 0.5s ease 0s;*/
    border-radius: 6px;
    // color: #fff;

}

.header__cont_phone {}

/* header end */
/* content start */
.content {
    position: relative;
    margin: 5rem 0 0 0
}



.contentFone {
    background-image: url(https://arkhangelsk.stroika-arenda.ru/images/bg-section.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // max-width: 1400px;
    width: 100%;
    margin: auto;
    // border-radius: 6px;

}

.content__menu {
    background-color: #21252e;
    padding: 0 1rem;
    width: 100%;
    max-width: 1170px;
    margin: auto;
    // position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    color: #fff;
    // border-radius: 25px 0;
    overflow: hidden;
    border: 1px solid;
    border-right-color: rgb(255, 221, 85);
    border-bottom-color: rgb(255, 221, 85);
    z-index: 2;

}

.content__menu_cont {
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    align-content: center
}

.content__menu_cont a {
    text-decoration: none;
    color: #fff;
    padding: 1rem;
    transition: all 0.3s ease 0s;
    /*    border-radius: 6px;*/
    text-transform: uppercase;


}

.content__menu_cont a:hover {
    color: #fd5;
    /*    box-shadow: inset 0 0 5px 3px #7a7579;*/
    background-color: #27375d;
    /*    overflow: hidden;*/
}

.content__seoTitle {
    color: #fff;
    padding: 3.5rem 0.5rem 0.5rem;
    font-size: 1.6rem;
    text-align: center;
}

.content__cont {
    justify-content: space-evenly;
    /*    background-color: #21222ee6;*/
    color: #fff;
    max-width: 1170px;
    margin: auto;
}

.content__cont_item {
    width: 100%;
    flex: 1 1 14%;
    height: 150px;
    padding: 0.5rem;
    margin: 0.5rem;
    text-align: center;
    align-items: baseline;
    align-content: center;
    justify-content: center;
    border: 0.015rem solid #80808021;
    transition: all 0.3s ease 0s;
}

.content__cont_item:hover {
    box-shadow: 0 0 7px 1px #fff;
    transition: all 0.3s ease 0s;

}

.content__cont_item:hover>.content__cont_item__img {
    padding: 0rem 0 0 0;
    transition: all 0.5s ease 0s;

}

.content__cont_item__img {
    width: 50px;
    height: 50px;
    padding: 1rem 0;
    transition: all 0.5s ease 0s;
    margin-bottom: 1rem;

}

.content__about {
    margin: auto;
    width: 100%;
    max-width: 1118px;
    padding: 1.5rem;
    font-size: 1rem;
}

.content__about_cont {
    // margin-bottom: 1rem;
}

.content__about_contS {}

.content__about_cont h2,
h3 {
    padding: 1rem 0.5rem;
    font-size: 1.6rem;
}

.boxDelivery {
    border: 1px solid #a9a9a940;
    transition: all 0.5s ease 0s;
}

.boxDelivery:hover {
    box-shadow: 0 0 5px 5px #a9a9a940;
}

.promotions {
    width: calc(100% - 1rem);
    max-width: 1400px;
    margin: auto;
    padding: 1rem 0.5rem;
}

.promotion__cont {
    font-size: 1rem;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    text-align: center;
}

.promotion__cont_items {
    flex: 1 1 20%;
    max-width: 200px;
    height: 210px;
    border: 1px solid #56555530;
    border-bottom-color: #0000004f;
    border-bottom-style: solid;
    border-bottom-width: 8px;
    border-right-color: #00000069;
    border-right-style: solid;
    border-right-width: 4px;
    box-shadow: 0 6px 10px 5px #a9a9a994;
    border-radius: 35px;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .promotion_block {
        right: -5px;
        position: absolute;
        width: 30px;
        height: 50%;
        background-color: #d5c3f1;
        top: 25%;
        border-radius: 20px 0 0 20px;
        box-shadow: inset 2px 0px 10px 0px #000000ba;
        z-index: 2;
    }
}

.promotion__cont_itemsImage {}

.promotion__cont_itemsImage img {
    width: 100%;
    max-width: 70px;
    margin: auto;
    margin-top: 0.5rem;
}

.promotion__cont_itemsText {}

/*
tabs start
*/
.content__tabs {
    height: auto;
    transition: 0.5s ease 0s;
}

.tab-container {
    background-color: #21222e;
    border-radius: 3px;
    overflow: hidden;
}

.tab-title {
    font-family: sans-serif;
    color: #fff;
    margin: 10px;
    font-weight: normal;
    font-size: 18px;
}

.tab-wrapper {
    width: 100%;
    /*    overflow-x: scroll;*/
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: sans-serif;
}

.tab-wrapper .tab-item,
.tab-wrapper input {
    display: none;
}

.tab-wrapper .tab-item {
    background-color: #f9f7f7;
    width: calc(100% - 40px);
    max-width: 100%;
    padding: 20px;
    order: 1;
}

.tab-wrapper label {
    height: 35px;
    line-height: 35px;
    min-width: 50px;
    padding: 0px 20px;
    text-align: center;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    cursor: pointer;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.tab-wrapper input:checked+label {
    border-bottom: 3px solid #ddb929;
}

.tab-wrapper input:checked+label+.tab-item {
    display: block;
}

.itemAbouts {
    flex: 1 1 15%;
    padding: 1rem
}

.itemAbout {
    flex: 2 1 15%;
    padding: 1rem
}

.itemAbouts h3,
.itemAbout h3 {
    font-size: 1.1rem
}

.itemAbouts p,
.itemAbout p {
    font-size: 1rem
}

/*
tabs end
*/

/*content end*/
/* footer start */
.footer {
    background-color: #21222e;
    color: rgba(255, 255, 255, .4);

}

.footer a {
    transition: 0.3s ease 0s;
    color: #fff;
}

.footer a:hover {

    color: #ddb929;
}



.footer__menu {
    font-size: 0.9rem;
    // flex: 9 1 10%;
    width: 100%;
    max-width: 1000px;
    min-width: 250px;
}

.footer__menu_cont {
    justify-content: space-between;
    align-items: flex-start;
}

.footer__menu_item {
    flex: 1 1 20%;
}

.footer__menu_item a {
    color: rgba(255, 255, 255, .4);
    text-decoration: none;
}

.footer__menu_item__text {

    color: #fff;
}

.footer__menu_item__link {

    font-size: 0.9rem;
}

.footer__menu_item__text,
.footer__menu_item__link {
    width: 100%;
    max-width: 245px;
}

.footer__menu_item__more {
    color: #ddb929;
    font-size: 0.7rem;
}

.footer__phone {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;

    /*flex: 2 1 10%;*/
}

.footer__map {

    width: 100%;
    max-width: 250px;
    align-content: space-around;
    align-items: stretch;

}

.footerLinkMap {
    margin: auto;
    max-width: 1400px;
}

/* footer end */
.atuin-btn {
    display: inline-flex;
    // margin: 10px;
    text-decoration: none;
    position: relative;
    font-size: 0.8rem;
    line-height: 20px;
    padding: 12px 30px;
    color: #FFF;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', Тahoma, sans-serif;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
}

.atuin-btn:hover,
.atuin-btn:active,
.atuin-btn:focus {
    color: #FFF;
}

.atuin-btn:before {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    bottom: 0;
    left: 0;
    border-right: 50px solid transparent;
    border-top: 50px solid #2D6B9F;
    transition: transform 1s;
    transform: translateX(-100%);
    z-index: -1;
}

.atuin-btn:hover:before,
.atuin-btn:active:before,
.atuin-btn:focus:before {
    transform: translateX(0);
}

.menuItemNone {
    display: none;
}

/* выпадающее меню */
/* Кнопка выпадающего списка */
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;

}

/* Контейнер <div> - необходим для размещения выпадающего содержимого */
.dropdown {
    position: relative;
    display: none;
    width: 100%;
    text-align: center;
}

/* Выпадающее содержимое (скрыто по умолчанию) */
.dropdown-content {
    // display: none;
    position: relative;
    background-color: #262525;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
    height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* Ссылки внутри выпадающего списка */
.dropdown-content a {
    color: #ffffff;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 0.8rem;
}

/* Изменение цвета выпадающих ссылок при наведении курсора */
.dropdown-content a:hover {
    background-color: rgb(129, 129, 129);
}

/* Показать выпадающее меню при наведении курсора */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Изменение цвета фона кнопки раскрывающегося списка при отображении содержимого раскрывающегося списка */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

/* выпадающее меню */
@media screen and (max-width:765px) {
    .promotion__cont_items {
        flex: 1 1 50%;

    }

    .promotion__cont_items {
        max-width: 150px;
        padding-bottom: 0.5rem;
        height: 100%;
        border: 1px solid #56555530;
        border-bottom-color: #56555578;
        border-bottom-style: solid;
        border-bottom-width: 8px;
        box-shadow: 0 6px 10px 5px #a9a9a994;
        border-radius: 20px;
        margin-bottom: 20px;

        .promotion_block {
            right: -7px;
            position: absolute;
            width: 30px;
            height: 50%;
            background-color: #d5c3f1;
            top: 25%;
            border-radius: 20px 0 0 20px;
            box-shadow: inset 2px 0px 10px 0px #000000ba;
            z-index: 2;
        }

        img {
            max-width: 45px;
            margin-top: 0.4rem;
        }
    }

    .promotion__cont_itemsText {
        p {
            min-height: 40px;
            max-width: 80%;
            font-size: 0.85rem;
            text-align: left;
            padding-left: 0.5rem;
        }

        h3 {
            font-size: 1.2rem;
            padding-top: 0.5rem;
            padding-left: 0.5rem;
            padding-bottom: 0;
            text-align: left;
        }
    }

    .content__seoTitle h1 {
        font-size: 1.3rem;
    }

    .footer__menu {
        display: none;
    }

    .footer__map {
        max-width: 100%;
    }

    .content__cont_item {
        width: 50%;
        min-width: 150px;
        height: auto;
        padding: 1rem;
        margin: 0rem;
        justify-content: space-between;
        border: 0.015rem solid #80808021;
        transition: none;
        align-items: center;
        flex-direction: column
    }

    .content__cont_item:hover {
        box-shadow: 0;
        transition: none;

    }

    .content__cont_item__img {
        padding: 0;
        margin-bottom: 0;
        transition: none;
    }

    .content__cont_item:hover>.content__cont_item__img {
        padding: 0;
    }

    .menuItemNone {
        display: block;
    }

    /* выпадающее меню */
    /* Кнопка выпадающего списка */
    .dropbtn {
        background-color: #4CAF50;
        color: white;
        padding: 16px;
        font-size: 16px;
        border: none;

    }

    /* Контейнер <div> - необходим для размещения выпадающего содержимого */
    .dropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    /* Выпадающее содержимое (скрыто по умолчанию) */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #262525;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        width: 100%;
    }

    /* Ссылки внутри выпадающего списка */
    .dropdown-content a {
        color: #ffffff;
        padding: 12px 16px;
        text-decoration: none;
        font-size: 0.8rem;
    }

    /* Изменение цвета выпадающих ссылок при наведении курсора */
    .dropdown-content a:hover {
        background-color: rgb(129, 129, 129);
    }

    /* Показать выпадающее меню при наведении курсора */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Изменение цвета фона кнопки раскрывающегося списка при отображении содержимого раскрывающегося списка */
    .dropdown:hover .dropbtn {
        background-color: #3e8e41;
    }

    /* выпадающее меню */
    .header__cont_menu a {
        text-decoration: none;
        // color: rgba(255, 255, 255, .4);
        font-size: 1rem;
        padding: 0;
        transition: all 0.5s ease 0s;
        margin: 0
    }
}

@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(../assets/fonts/znikomitno25/znikomitno25.woff) format('woff');
    src: local('MyFont'), url(../assets/fonts/znikomitno25/znikomitno25.ttf) format('ttf');
    src: local('MyFont'), url(../assets/fonts/znikomitno25/znikomitno25.woff2) format('woff2');

}
@font-face {
    font-family: 'MyF';
    src: local('MyF'), url(../assets/fonts/myslnarrowc/myslnarrowc.woff) format('woff');
    src: local('MyF'), url(../assets/fonts/myslnarrowc/myslnarrowc.ttf) format('ttf');
    src: local('MyF'), url(../assets/fonts/myslnarrowc/myslnarrowc.woff2) format('woff2');

}

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap');

a,
// p,
span,
li,
h2,
h3,
h4,
h5 {
    font-family: 'Montserrat Alternates';

}
p{
    font-family: Palatino Linotype, Book Antiqua, Palatino, serif;
}

a {
    color: #000000
}

a:hover {
    color: #ddb929
}

h1 {
    font-family: 'MyFont'
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 900;

}

.btns {
    margin: 0 auto 0.3rem;
    padding: 0.5rem 20px;
    width: max-content;
    overflow: hidden;
    font-family: 'Montserrat Alternates', sans-serif;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9rem;
    border-width: 0;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    outline: none;
    cursor: pointer;
    border-radius: 30px;
}

.btn1 {
    background: linear-gradient(90deg, #c600b2, #7619fd);
}

.btn2 {
    background: linear-gradient(90deg, #a3ffb0, #2bd9e8);
}

.btn3 {
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
}

.btns:before,
.btns:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: -110%;
    top: 0;
    opacity: 0.1;
    background: black;
    transform: skewX(25deg);
    transition: .5s ease;
}

.btns:hover:before {
    left: -10%;
    transition: .3s ease;
}

.btns:hover:after {
    left: -20%;
}

@media (max-width:1280px) {
    .wdCont {
        height: auto;
        margin: auto;
        width: 100%;
        bottom: auto;
        position: relative;
    }

    .footer__map {
        width: 100%;
        max-width: 100%;
        align-content: space-around;
        align-items: stretch;
        display: flex;

        div {
            flex: 1 1 50%;
            padding: 0.3rem;
        }
    }
}

@media (max-width:450px) {
    * {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .footer__map {
        width: 100%;
        max-width: 400px;
        display: block;

        div {

            padding: 0rem;
        }
    }

    .slick-dots li {
        width: 30px;
        height: 30px;
    }

    .contPhotoGalary {

        a {

            img {
                width: 145px;
                height: 145px;
            }
        }

    }
}

@media (max-width:340px) {
    .roomSlides {
        margin-bottom: 30px;

        .slick-dots {
            bottom: 15px;
            width: calc(100% - 1rem);
            padding: 0.5rem !important;
        }
    }

    .main-cont {
        h3 {
            padding: 0.5rem 0.2rem;
        }
    }

    .contPhotoGalary {

        a {

            img {
                width: 115px;
                height: 115px;
            }
        }

    }
}



#toTop {
    position: fixed;
    display: inline-block;
    bottom: 30px;
    right: -40px;
    width: 50px;
    height: 50px;
    z-index: 1000;
    cursor: pointer;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    color: #000;
}

.borderCont {
    width: 100%;
    height: 100%;
    // filter: invert(1) grayscale(1) contrast(9);

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.644);

    img {
        opacity: 0.5;
    }

    p:after {
        mix-blend-mode: difference;
        color: #cccccc;
        content: "вверх";
    }
}

#toTop:hover {
    .borderCont {

        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.781);

        img {
            opacity: 0.9;
        }

        p:after {
            mix-blend-mode: difference;
            color: #cccccc;
            content: "вверх";
        }
    }
}

#toTop svg {
    position: relative;
    top: 12px;
    right: -1px;
    fill: #fff;
    transition: all .3s ease-in-out;
}

#toTop:hover svg {
    cursor: pointer;
    fill: #fff;
    transition: all .3s ease-in-out;
}

#toTop.show {
    opacity: 1;
    visibility: visible;
    right: calc(2%);
}


table.miyazaki {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: auto;
}

table.miyazaki thead th {
    font-weight: 600;
}

table.miyazaki thead th,
table.miyazaki tbody td {
    padding: 0.3rem;
    font-size: 1.1rem;
}

table.miyazaki tbody td {
    padding: 0.8rem;
    font-size: 1rem;
    color: #444;
    background: #eee;
}

table.miyazaki tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 600px) {
    table.miyazaki {
        overflow: hidden;
    }

    table.miyazaki thead {
        display: none;
    }

    table.miyazaki tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table.miyazaki tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table.miyazaki tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 5.5rem;
    }
    table.miyazaki.deliv tbody td:before {
        width: 8rem;
    }
}


table#miyazaki {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: auto;
}

table#miyazaki thead th {
    font-weight: 600;
}

table#miyazaki thead th,
table#miyazaki tbody td {
    padding: 0.3rem;
    font-size: 1.1rem;
}

table#miyazaki tbody td {
    padding: 0.8rem;
    font-size: 1rem;
    color: #444;
    background: #eee;
}

table#miyazaki tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 600px) {
    table#miyazaki {
        overflow: hidden;
    }

    table#miyazaki thead {
        display: none;
    }

    table#miyazaki tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table#miyazaki tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table#miyazaki tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 5.5rem;
    }
    table#miyazaki.deliv tbody td:before {
        width: 8rem;
    }
}

table#miyazaki2 {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: auto;
}

table#miyazaki2 thead th {
    font-weight: 600;
}

table#miyazaki2 thead th,
table#miyazaki2 tbody td {
    padding: 0.3rem;
    font-size: 1.1rem;
}

table#miyazaki2 tbody td {
    padding: 0.8rem;
    font-size: 1rem;
    color: #444;
    background: #eee;
}

table#miyazaki2 tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 600px) {
    table#miyazaki2 {
        overflow: hidden;
    }

    table#miyazaki2 thead {
        display: none;
    }

    table#miyazaki2 tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table#miyazaki2 tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table#miyazaki2 tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 7.5rem;
        font-weight: bolder;
        text-decoration: underline;
    }
    table#miyazaki2.deliv tbody td:before {
        width: 8rem;
    }
}

table#miyazaki3 {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: auto;
}

table#miyazaki3 thead th {
    font-weight: 600;
}

table#miyazaki3 thead th,
table#miyazaki3 tbody td {
    padding: 0.3rem;
    font-size: 1.1rem;
}

table#miyazaki3 tbody td {
    padding: 0.8rem;
    font-size: 1rem;
    color: #444;
    background: #eee;
}

table#miyazaki3 tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 600px) {
    table#miyazaki3 {
        overflow: hidden;
    }

    table#miyazaki3 thead {
        display: none;
    }

    table#miyazaki3 tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table#miyazaki3 tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table#miyazaki3 tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 9.5rem;
        font-weight: bolder;
        text-decoration: underline;
    }
    table#miyazaki3.deliv tbody td:before {
        width: 8rem;
    }
}

@media screen and  (max-width: 650px){
    .miya{
        display: block;
    }   
}
table#miyazaki5 {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 460px;
    margin: auto;
}

table#miyazaki5 thead th {
    font-weight: 600;
}

table#miyazaki5 thead th,
table#miyazaki5 tbody td {
    padding: 0.3rem;
    font-size: 1.1rem;
}

table#miyazaki5 tbody td {
    padding: 0.4rem;
    font-size: 1rem;
    color: #444;
    background: #eee;
}

table#miyazaki5 tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 1100px){
    table#miyazaki5 {
        max-width: 370px;
    }
}

@media screen and (max-width: 600px) {
    table#miyazaki5 {
        overflow: hidden;
        max-width:none;
    }

    table#miyazaki5 thead {
        display: none;
    }

    table#miyazaki5 tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table#miyazaki5 tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table#miyazaki5 tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width:11.5rem;
        font-weight: bolder;
        text-decoration: underline;
    }
    table#miyazaki5.deliv tbody td:before {
        width: 8rem;
    }
}

table#miyazaki6 {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 460px;
    margin: auto;
}

table#miyazaki6 thead th {
    font-weight: 600;
}

table#miyazaki6 thead th,
table#miyazaki6 tbody td {
    padding: 0.3rem;
    font-size: 1.1rem;
}

table#miyazaki6 tbody td {
    padding: 0.4rem;
    font-size: 1rem;
    color: #444;
    background: #eee;
}

table#miyazaki6 tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 1100px){
    table#miyazaki6 {
        max-width: 370px;
    }
}
@media screen and (max-width: 600px) {
    table#miyazaki6 {
        overflow: hidden;
        max-width:none;
    }

    table#miyazaki6 thead {
        display: none;
    }

    table#miyazaki6 tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table#miyazaki6 tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table#miyazaki6 tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width:11.5rem;
        font-weight: bolder;
        text-decoration: underline;
    }
    table#miyazaki6.deliv tbody td:before {
        width: 8rem;
    }
}

table#miyazaki4 {
    border-collapse: collapse;
    font-family: Agenda-Light;
    font-weight: 100;
    background: linear-gradient(90deg, #49c2ff, #6a27d2);
    color: #fff;
    text-rendering: optimizeLegibility;
    border-radius: 10px;
    width: 100%;
    max-width: 140px;
    margin: auto;
}

table#miyazaki4 thead th {
    font-weight: 600;
}

table#miyazaki4 thead th,
table#miyazaki4 tbody td {
    padding: 0.3rem;
    font-size: 0.85rem;
}

table#miyazaki4 tbody td {
    padding: 0.4rem;
    font-size: 0.8rem;
    color: #444;
    background: #eee;
}

table#miyazaki4 tbody tr:not(:last-child) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 900px){
    table#miyazaki4 {
        max-width: 80px;
    }
}
@media screen and (max-width: 600px) {
    table#miyazaki4 {
        overflow: hidden;
        max-width:none;
    }

    table#miyazaki4 thead {
        display: none;
    }

    table#miyazaki4 tbody td {
        display: block;
        padding: 0.25rem;
        font-size: 0.8rem;
        font-weight: bold;
    }

    table#miyazaki4 tbody tr td:first-child {
        background: rgba(51, 51, 51, 0.363);
        color: #fff;
    }

    table#miyazaki4 tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 13.5rem;
        font-weight: bolder;
        text-decoration: underline;
    }
    table#miyazaki4.deliv tbody td:before {
        width: 8rem;
    }
}