.green {
    background-color: #4CAF50 !important
}

.green-text {
    color: #4CAF50 !important
}

.green.lighten-6 {
    background-color: #e8f5e900 !important
}

.green.lighten-5 {
    background-color: #E8F5E9 !important
}

.green-text.text-lighten-5 {
    color: #E8F5E9 !important
}

.green.lighten-4 {
    background-color: #C8E6C9 !important
}

.green-text.text-lighten-4 {
    color: #C8E6C9 !important
}

.green.lighten-3 {
    background-color: #A5D6A7 !important
}

.green-text.text-lighten-3 {
    color: #A5D6A7 !important
}

.green.lighten-2 {
    background-color: #81C784 !important
}

.green-text.text-lighten-2 {
    color: #81C784 !important
}

.green.lighten-1 {
    background-color: #43a548 !important
}

.green.lighten-1:hover {
    background-color: #129619 !important
}

.green-text.text-lighten-1 {
    color: #66BB6A !important
}

.green.darken-1 {
    background-color: #3c773f !important
}

.green-text.text-darken-1 {
    color: #255d27 !important
}

.green.darken-2 {
    background-color: #388E3C !important
}

.green-text.text-darken-2 {
    color: #388E3C !important
}

.green.darken-3 {
    background-color: #2E7D32 !important
}

.green-text.text-darken-3 {
    color: #2E7D32 !important
}#28932d

.green.darken-4 {
    background-color: #1B5E20 !important
}

.green.darken-5 {
    background-color: #05460a  !important
}

.green-text.text-darken-4 {
    color: #1B5E20 !important
}

.green.accent-1 {
    background-color: #B9F6CA !important
}

.green-text.text-accent-1 {
    color: #B9F6CA !important
}

.green.accent-2 {
    background-color: #69F0AE !important
}

.green-text.text-accent-2 {
    color: #69F0AE !important
}

.green.accent-3 {
    background-color: #00E676 !important
}

.green-text.text-accent-3 {
    color: #00E676 !important
}

.green.accent-4 {
    background-color: #00C853 !important
}

.green-text.text-accent-4 {
    color: #00C853 !important
}



.green-fill {
    fill: #4CAF50 !important
}

.green-fill.fill-lighten-5 {
    fill: #E8F5E9 !important
}

.green-fill.fill-lighten-4 {
    // fill: #C8E6C9 !important 
    fill: #42cba5 !important 
}
.green-fill.fill-lighten-4:hover {
    fill: #C8E6C9 !important 
}



.green-fill.fill-lighten-3 {
    fill: #A5D6A7 !important
}

.green-fill.fill-lighten-2 {
    fill: #81C784 !important
}

.green-fill.fill-lighten-1 {
    fill: #66BB6A !important
}

.green-fill.fill-darken-1 {
    fill: #43A047 !important
}

.green-fill.fill-darken-2 {
    fill: #388E3C !important
}

.green-fill.fill-darken-3 {
    fill: #2E7D32 !important
}


.green-fill.fill-darken-4 {
    fill: #1B5E20 !important
}

.green-fill.fill-accent-1 {
    fill: #B9F6CA !important
}

.green-fill.fill-accent-2 {
    fill: #69F0AE !important
}


.green-fill.fill-accent-3 {
    fill: #00E676 !important
}


.green-fill.fill-accent-4 {
    fill: #00C853 !important
}