.contMenu {}

.menuBody {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0 0 5px 0 #000;
}

.navMenu {

    // margin: 20px 0 10px 0;
    img {
        display: none;
    }
}

.burgerMenu {
    // display: none;
}

.item {
    padding: 5px 7px;
    min-height: 25px;
    color: #000;
    line-height: 35px;
    transition: all 0.3s ease 0s;
    border-radius: 4px;
}

.item:hover {
    background-color: rgba(255, 255, 255, 0.38);
    border-radius: 4px;
}

a {
    text-decoration: none;
}

.activeLink {
    color: #c9bb21 !important;
    background-color: rgba(255, 255, 255, 0.18);
    border-radius: 4px;
}

.hr {
    display: none;
}

@media (max-width:900px) {
    .menuContainerPhone{
        overflow: scroll;
    }
    .contMenu {
        width: 100%;
        top: 0;
        left: 0%;
        z-index: 50;
    }

    .menuBody {
        padding: 0.5rem;
    }

    .contMenu::before {
        // content: '';
        // position: absolute;
        // top: 0%;
        // left: 0%;
        // width: 100%;
        // height: 100%;
        // background-color: #5e5373;
        // z-index: 3;
    }

    .item {
        padding: 5px 15px;
        margin: 10px 0;
        font-size: 2rem;
        width: 90%;
        border-radius: 0;
        text-align: center;
    }
    .itemMenu{
        font-weight: bold;
    }
    .navMenu {
        position: fixed;
        display: flex;
        top: 0%;
        left: -120%;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: rgba(25, 26, 26, 0.281);
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        transition: all ease 0.5s 0s;
img{
    display: block;
    max-width: 100%;
}
        .hr {
            display: block;
            color: #000;
        }
    }

    .navMenu.active {
        position: fixed;
        top: 0px;
        left: 0%;
        width: 50%;
        height: 100%;
        margin: 0;
        background-color: #ffffff;

        .blur {
            width: 100%;
            height: 100vh;
            backdrop-filter: blur(2px);
            position: fixed;
            right: -50%;
            background-color: #00000050;
            // transition: all 1s ease  0s!important;
        }
    }

    .blur {
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(0px);
        position: fixed;
        right: -120%;
        // transition: all 0.2s ease  0s!important;
    }

    .burgerMenu {
        position: fixed;
        right: 10px;
        top: 10px;
        height: 35px;
        z-index: 999999;
        display: block;
        background-color: #ffffffa6;
        // border: 0.2px solid;
        width: 35px;
        cursor: pointer;

    }

    .burgerMenu.active {
        position: fixed;
        right: 10px;
        top: 10px;
        height: 35px;
        z-index: 999999;
        display: block;
        background-color: #ffffffa6;
        // border: 0.2px solid;
        width: 35px;
        cursor: pointer;

    }

    .burgerMenu::before,
    .burgerMenu::after,
    .burgerMenu span {
        position: absolute;
        width: 22px;
        height: 2px;
        background-color: #000;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.3s ease 0s;
    }

    .burgerMenu::before,
    .burgerMenu::after {
        content: '';
    }

    .burgerMenu::before {
        top: 20%;
    }

    .burgerMenu::after {
        bottom: 20%;
    }

    .burgerMenu span {

        top: 50%;
        transform: scale(1) translate(0px, -50%);
    }

    .menuBody {
        a {
            width: 100%;
            z-index: 0;
            color: #000000;
            font-size: 0.8rem;
        }
    }

    .activeLink {
        background-color: rgba(255, 255, 255, 0.18);
        border-radius: 4px;

    }

    .item {

        font-size: 2rem;
        width: 90%;
        border-radius: 0;
        text-align: left;
        min-height: 51px;
    }
}