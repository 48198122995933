.anapa-durso-tower {
    background-color: #3e2723 !important;
    color: #ffffff !important
}

.anapa-durso-tower-text.text-lighten-5 {
    color: #ffffff !important
}

.anapa-durso-tower.lighten-5 {
    background-color: #f6f9fd !important;
    color: #000000 !important
}

.anapa-durso-tower.lighten-4 {
    background-color: #ffffe5 !important;
    color: #000000 !important
}

.anapa-durso-tower.lighten-3 {
    background-color: #c3be83 !important;
    color: #000000 !important
}

.anapa-durso-tower.lighten-2 {
    background-color: #3e2723 !important;
    color: #ffffff !important
}

.anapa-durso-tower.lighten-1 {
    background-color: #3e2723 !important;
    color: #ffffff !important
}

.anapa-durso-tower {
    background-color: #3e2723 !important;
    color: #ffffff !important
}

.anapa-durso-tower.darken-1 {
    background-color: #3e2723 !important;
    color: #ffffff !important
}

.anapa-durso-tower.darken-2 {
    background-color: #3e2723 !important;
    color: #ffffff !important
}

.anapa-durso-tower.darken-3 {
    background-color: #421a3c !important;
    color: #ffffff !important;
    transition: .5s;
}

.anapa-durso-tower.darken-3:hover {
    background-color: #C7BE7F !important;
    color: #000 !important;
    transition: .5s;
}

.anapa-durso-tower.darken-4 {
    background-color: #443442 !important;
    color: #ffffff !important
}