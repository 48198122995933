.imgContPrew {
    width: 100%;
    height: 45vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: #dda93b;
    display: flex;
    align-items: center;

    h1 {
        color: #fff;
        // max-width: 590px;
        font-size: 2rem;
        text-shadow: 0 0 5px black;
    }
}

.imgContPrew::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000075;
}

@media screen and (max-width:1000px) {

    .imgContPrew {
        height: 190px;
        background-position: center;

        h1 {
            font-size: 1.6rem;
        }
    }
}

@media screen and (max-width: 765px) {

    .imgContPrew {
        height: 230px;

        h1 {
            font-size: 1.6rem;
        }
    }
}