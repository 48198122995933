.greenpark {
    background-color: #4CAF50 !important
}

.greenpark-text {
    color: #4CAF50 !important
}

.greenpark.lighten-5 {
    background-color: #ffffff !important
}

.greenpark-text.text-lighten-5 {
    color: #E8F5E9 !important
}

.greenpark.lighten-4 {
    background-color: #C8E6C9 !important
}

.greenpark-text.text-lighten-4 {
    color: #C8E6C9 !important
}

.greenpark.lighten-3 {
    background-color: #A5D6A7 !important
}

.greenpark-text.text-lighten-3 {
    color: #A5D6A7 !important
}

.greenpark.lighten-2 {
    background-color: #81C784 !important
}

.greenpark-text.text-lighten-2 {
    color: #81C784 !important
}

.greenpark.lighten-1 {
    background-color: #0e8a14 !important
}

.greenpark-text.text-lighten-1 {
    color: #66BB6A !important
}

.greenpark.darken-1 {
    background-color: #43A047 !important
}

.greenpark-text.text-darken-1 {
    color: #43A047 !important
}

.greenpark.darken-2 {
    background-color: #388E3C !important
}

.greenpark-text.text-darken-2 {
    color: #388E3C !important
}

.greenpark.darken-3 {
    background-color: #ffee00 !important;
    color: #000;
    transition: .5s;
}

.greenpark.darken-3:hover {
    background-color: #ffe400 !important;
    color: #000;
    border: 3px solid #ffee00;
    box-shadow: 0 4px 5px 0px black;
    text-shadow: 0 0 1px black;
    transition: .5s;
}

.greenpark-text.text-darken-3 {
    color: #2E7D32 !important
}

.greenpark.darken-4 {
    background-color: #1a8d62 !important
}

.greenpark-text.text-darken-4 {
    color: #1B5E20 !important
}

.greenpark.accent-1 {
    background-color: #B9F6CA !important
}

.greenpark-text.text-accent-1 {
    color: #B9F6CA !important
}

.greenpark.accent-2 {
    background-color: #69F0AE !important
}

.greenpark-text.text-accent-2 {
    color: #69F0AE !important
}

.greenpark.accent-3 {
    background-color: #00E676 !important
}

.greenpark-text.text-accent-3 {
    color: #00E676 !important
}

.greenpark.accent-4 {
    background-color: #00C853 !important
}

.greenpark-text.text-accent-4 {
    color: #00C853 !important
}

.greenpark-fill {
    fill: #4CAF50 !important
}

.greenpark-fill.fill-lighten-5 {
    fill: #E8F5E9 !important
}

.greenpark-fill.fill-lighten-4 {
    fill: #C8E6C9 !important
}


.greenpark-fill.fill-lighten-3 {
    fill: #A5D6A7 !important
}

.greenpark-fill.fill-lighten-2 {
    fill: #81C784 !important
}

.greenpark-fill.fill-lighten-1 {
    fill: #66BB6A !important
}

.greenpark-fill.fill-darken-1 {
    fill: #43A047 !important
}

.greenpark-fill.fill-darken-2 {
    fill: #388E3C !important
}

.greenpark-fill.fill-darken-3 {
    fill: #2E7D32 !important
}


.greenpark-fill.fill-darken-4 {
    fill: #1B5E20 !important
}

.greenpark-fill.fill-accent-1 {
    fill: #B9F6CA !important
}

.greenpark-fill.fill-accent-2 {
    fill: #69F0AE !important
}


.greenpark-fill.fill-accent-3 {
    fill: #00E676 !important
}


.greenpark-fill.fill-accent-4 {
    fill: #00C853 !important
}