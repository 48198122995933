.cyan {
    background-color: #00bcd4 !important
}

.cyan.lighten-1 {
    background-color: #26c6da !important
}

.cyan.lighten-1:hover {
    background-color: #20e1fa !important
}

.cyan.lighten-2 {
    background-color: #4dd0e1 !important
}

.cyan.lighten-3 {
    background-color: #80deea !important
}

.cyan.lighten-4 {
    background-color: #b2ebf2 !important
}

.cyan.lighten-5 {
    background-color: #e0f7fa !important
}

.cyan.lighten-6 {
    background-color: #00bcd400 !important
}


.cyan.darken-1 {
    background-color: #00acc1 !important
}

.cyan.darken-2 {
    background-color: #0097a7 !important
}

.cyan.darken-3 {
    background-color: #00838f !important
}

.cyan.darken-4 {
    background-color: #006064 !important
}
.cyan.darken-5 {
    background-color: #0093a7 !important
}

.cyan.accent-1 {
    background-color: #84ffff !important
}

.cyan.accent-2 {
    background-color: #18ffff !important
}

.cyan.accent-3 {
    background-color: #00e5ff !important
}

.cyan.accent-4 {
    background-color: #00b8d4 !important
}


.cyan-text {
    color: #00bcd4 !important
}


.cyan-text.text-lighten-1 {
    color: #26c6da !important
}

.cyan-text.text-lighten-2 {
    color: #4dd0e1 !important
}

.cyan-text.text-lighten-3 {
    color: #80deea !important
}

.cyan-text.text-lighten-4 {
    color: #b2ebf2 !important
}

.cyan-text.text-lighten-5 {
    color: #e0f7fa !important
}

.cyan-text.text-lighten-6 {
    color: #ffffff !important
}

.cyan-text.text-darken-1 {
    color: #00acc1 !important
}

.cyan-text.text-darken-2 {
    color: #0097a7 !important
}

.cyan-text.text-darken-3 {
    color: #00838f !important
}

.cyan-text.text-darken-4 {
    color: #006064 !important
}


.cyan-text.text-accent-1 {
    color: #84ffff !important
}

.cyan-text.text-accent-2 {
    color: #18ffff !important
}

.cyan-text.text-accent-3 {
    color: #00e5ff !important
}

.cyan-text.text-accent-4 {
    color: #00b8d4 !important
}