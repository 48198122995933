.azure-b {
    background-color: #2196F3 !important
}

.azure-b-text {
    color: #2196F3 !important
}

.azure-b.lighten-5 {
    background-color: #E3F2FD !important
}

.azure-b-text.text-lighten-5 {
    color: #E3F2FD !important
}

.azure-b.lighten-4 {
    background-color: #BBDEFB !important
}

.azure-b-text.text-lighten-4 {
    color: #BBDEFB !important
}

.azure-b.lighten-3 {
    background-color: #90CAF9 !important
}

.azure-b-text.text-lighten-3 {
    color: #90CAF9 !important
}

.azure-b.lighten-2 {
    background-color: #64B5F6 !important
}

.azure-b-text.text-lighten-2 {
    color: #64B5F6 !important
}

.azure-b.lighten-1 {
    background-color: #42A5F5 !important
}

.azure-b-text.text-lighten-1 {
    color: #42A5F5 !important
}

.azure-b.darken-1 {
    background-color: #1E88E5 !important
}

.azure-b-text.text-darken-1 {
    color: #1E88E5 !important
}

.azure-b.darken-2 {
    background-color: #1976D2 !important
}

.azure-b-text.text-darken-2 {
    color: #1976D2 !important
}

.azure-b.darken-3 {
    background-color: #1565C0 !important
}

.azure-b-text.text-darken-3 {
    color: #1565C0 !important
}

.azure-b.darken-4 {
    background-color: #34495e !important
}

.azure-b-text.text-darken-4 {
    color: #0D47A1 !important
}

.azure-b.accent-1 {
    background-color: #82B1FF !important
}

.azure-b-text.text-accent-1 {
    color: #82B1FF !important
}

.azure-b.accent-2 {
    background-color: #448AFF !important
}

.azure-b-text.text-accent-2 {
    color: #448AFF !important
}

.azure-b.accent-3 {
    background-color: #2979FF !important
}

.azure-b-text.text-accent-3 {
    color: #2979FF !important
}

.azure-b.accent-4 {
    background-color: #2962FF !important
}

.azure-b-text.text-accent-4 {
    color: #2962FF !important
}


.azure-b-fill {
    fill: #2196F3 !important
}

.azure-b-fill.fill-lighten-5 {
    fill: #E3F2FD !important
}

.azure-b-fill.fill-lighten-4 {
    fill: #BBDEFB !important
}

.azure-b-fill.fill-lighten-3 {
    fill: #90CAF9 !important
}

.azure-b-fill.fill-lighten-2 {
    fill: #64B5F6 !important
}

.azure-b-fill.fill-lighten-1 {
    fill: #42A5F5 !important
}

.azure-b-fill.fill-darken-1 {
    fill: #1E88E5 !important
}

.azure-b-fill.fill-darken-2 {
    fill: #1976D2 !important
}

.azure-b-fill.fill-darken-3 {
    fill: #1565C0 !important
}

.azure-b-fill.fill-darken-4 {
    fill: #0D47A1 !important
}

.azure-b-fill.fill-accent-1 {
    fill: #82B1FF !important
}

.azure-b-fill.fill-accent-2 {
    fill: #448AFF !important
}

.azure-b-fill.fill-accent-3 {
    fill: #2979FF !important
}

/*Anapa - Azure-blue style rgba(41, 37, 29, 0.7)*/
/*Anapa - Azure style*/
.azure {
    background-color: #2196F3 !important
}

.azure-text {
    color: #bb9e60 !important
}

.azure.lighten-5 {
    background-color: #E3F2FD !important
}

.azure-text.text-lighten-5 {
    color: #E3F2FD !important
}

.azure.lighten-4 {
    background-color: #BBDEFB !important
}

.azure-text.text-lighten-4 {
    color: #BBDEFB !important
}

.azure.lighten-3 {
    background-color: #90CAF9 !important
}

.azure-text.text-lighten-3 {
    color: #90CAF9 !important
}

.azure.lighten-2 {
    background-color: #64B5F6 !important
}

.azure-text.text-lighten-2 {
    color: #64B5F6 !important
}

.azure.lighten-1 {
    background-color: #42A5F5 !important
}

.azure-text.text-lighten-1 {
    color: #bb9e60 !important
}

.azure.darken-1 {
    background-color: #1E88E5 !important
}

.azure-text.text-darken-1 {
    color: #1E88E5 !important
}

.azure.darken-2 {
    background-color: #1976D2 !important
}

.azure-text.text-darken-2 {
    color: #1976D2 !important
}

.azure.darken-3 {
    background-color: #bb9e60 !important
}

.azure-text.text-darken-3 {
    color: #1565C0 !important
}

.azure.darken-4 {
    background-color: rgba(41, 37, 29, 0.7) !important
}

.azure-text.text-darken-4 {
    color: #0D47A1 !important
}

.azure.accent-1 {
    background-color: #82B1FF !important
}

.azure-text.text-accent-1 {
    color: #82B1FF !important
}

.azure.accent-2 {
    background-color: #448AFF !important
}

.azure-text.text-accent-2 {
    color: #448AFF !important
}

.azure.accent-3 {
    background-color: #2979FF !important
}

.azure-text.text-accent-3 {
    color: #2979FF !important
}

.azure.accent-4 {
    background-color: #2962FF !important
}

.azure-text.text-accent-4 {
    color: #2962FF !important
}


.azure-fill {
    fill: #2196F3 !important
}

.azure-fill.fill-lighten-5 {
    fill: #E3F2FD !important
}

.azure-fill.fill-lighten-4 {
    fill: #BBDEFB !important
}

.azure-fill.fill-lighten-3 {
    fill: #90CAF9 !important
}

.azure-fill.fill-lighten-2 {
    fill: #64B5F6 !important
}

.azure-fill.fill-lighten-1 {
    fill: #42A5F5 !important
}

.azure-fill.fill-darken-1 {
    fill: #bb9e60 !important
}

.azure-fill.fill-darken-2 {
    fill: #1976D2 !important
}

.azure-fill.fill-darken-3 {
    fill: #1565C0 !important
}

.azure-fill.fill-darken-4 {
    fill: #0D47A1 !important
}

.azure-fill.fill-accent-1 {
    fill: #82B1FF !important
}

.azure-fill.fill-accent-2 {
    fill: #448AFF !important
}

.azure-fill.fill-accent-3 {
    fill: #2979FF !important
}