.light-green {
    background-color: #8bc34a !important
}

.light-green-text {
    color: #8bc34a !important
}

.light-green.lighten-6 {
    background-color: #f1f8e900 !important
}

.light-green.lighten-5 {
    background-color: #f1f8e9 !important
}

.light-green-text.text-lighten-5 {
    color: #f1f8e9 !important
}

.light-green.lighten-4 {
    background-color: #dcedc8 !important
}

.light-green-text.text-lighten-4 {
    color: #dcedc8 !important
}

.light-green.lighten-3 {
    background-color: #c5e1a5 !important
}

.light-green-text.text-lighten-3 {
    color: #c5e1a5 !important
}

.light-green.lighten-2 {
    background-color: #aed581 !important
}

.light-green-text.text-lighten-2 {
    color: #aed581 !important
}

.light-green.lighten-1 {
    background-color: #5ea70c !important
}

.light-green.lighten-1:hover {
    background-color: #498108 !important
}

.light-green-text.text-lighten-1 {
    color: #9ccc65 !important
}

.light-green.darken-1 {
    background-color: #6a8f44 !important
}

.light-green-text.text-darken-1 {
    color: #51752b !important
}

.light-green.darken-2 {
    background-color: #689f38 !important
}

.light-green-text.text-darken-2 {
    color: #689f38 !important
}

.light-green.darken-3 {
    background-color: #558b2f !important
}

.light-green-text.text-darken-3 {
    color: #558b2f !important
}

.light-green.darken-4 {
    background-color: #33691e !important
}

.light-green.darken-5 {
    background-color: #389214 !important
}

.light-green-text.text-darken-4 {
    color: #33691e !important
}

.light-green.accent-1 {
    background-color: #ccff90 !important
}

.light-green-text.text-accent-1 {
    color: #ccff90 !important
}

.light-green.accent-2 {
    background-color: #b2ff59 !important
}

.light-green-text.text-accent-2 {
    color: #b2ff59 !important
}

.light-green.accent-3 {
    background-color: #76ff03 !important
}

.light-green-text.text-accent-3 {
    color: #76ff03 !important
}

.light-green.accent-4 {
    background-color: #64dd17 !important
}

.light-green-text.text-accent-4 {
    color: #64dd17 !important
}


.light-green-fill {
    fill: #8bc34a !important
}

.light-green-fill.fill-lighten-5 {
    fill: #f1f8e9 !important
}

.light-green-fill.fill-lighten-4 {
    fill: #dcedc8 !important
}

.light-green-fill.fill-lighten-3 {
    fill: #c5e1a5 !important
}


.light-green-fill.fill-lighten-2 {
    fill: #aed581 !important
}


.light-green-fill.fill-lighten-1 {
    fill: #9ccc65 !important
}

.light-green-fill.fill-darken-1 {
    fill: #7cb342 !important
}


.light-green-fill.fill-darken-2 {
    fill: #689f38 !important
}


.light-green-fill.fill-darken-3 {
    fill: #558b2f !important
}

.light-green-fill.fill-darken-4 {
    fill: #33691e !important
}


.light-green-fill.fill-accent-1 {
    fill: #ccff90 !important
}

.light-green-fill.fill-accent-2 {
    fill: #b2ff59 !important
}

.light-green-fill.fill-accent-3 {
    fill: #76ff03 !important
}

.light-green-fill.fill-accent-4 {
    fill: #64dd17 !important
}