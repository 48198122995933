.indigo {
    background-color: #3f51b5 !important
}

.indigo-text {
    color: #3f51b5 !important
}

.indigo.lighten-5 {
    background-color: #e8eaf6 !important
}

.indigo-text.text-lighten-5 {
    color: #e8eaf6 !important
}

.indigo.lighten-4 {
    background-color: #c5cae9 !important
}

.indigo-text.text-lighten-4 {
    color: #c5cae9 !important
}

.indigo.lighten-3 {
    background-color: #9fa8da !important
}

.indigo-text.text-lighten-3 {
    color: #9fa8da !important
}

.indigo.lighten-2 {
    background-color: #7986cb !important
}

.indigo-text.text-lighten-2 {
    color: #7986cb !important
}

.indigo.lighten-1 {
    background-color: #5c6bc0 !important
}

.indigo.lighten-1:hover {
    background-color: #4252aa !important
}

.indigo-text.text-lighten-1 {
    color: #5c6bc0 !important
}

.indigo.darken-1 {
    background-color: #515882 !important
}

.indigo-text.text-darken-1 {
    color: #3949ab !important
}

.indigo.darken-2 {
    background-color: #303f9f !important
}

.indigo-text.text-darken-2 {
    color: #303f9f !important
}

.indigo.darken-3 {
    background-color: #283593 !important
}

.indigo-text.text-darken-3 {
    color: #283593 !important
}

.indigo.darken-4 {
    background-color: #1a237e !important
}

.indigo-text.text-darken-4 {
    color: #1a237e !important
}

.indigo.accent-1 {
    background-color: #8c9eff !important
}

.indigo-text.text-accent-1 {
    color: #8c9eff !important
}

.indigo.accent-2 {
    background-color: #536dfe !important
}

.indigo-text.text-accent-2 {
    color: #536dfe !important
}

.indigo.accent-3 {
    background-color: #3d5afe !important
}

.indigo-text.text-accent-3 {
    color: #3d5afe !important
}

.indigo.accent-4 {
    background-color: #304ffe !important
}

.indigo-text.text-accent-4 {
    color: #304ffe !important
}


.indigo-fill {
    fill: #3f51b5 !important
}

.indigo-fill.fill-lighten-5 {
    fill: #e8eaf6 !important
}

.indigo-fill.fill-lighten-4 {
    fill: #c5cae9 !important
}

.indigo-fill.fill-lighten-3 {
    fill: #9fa8da !important
}

.indigo-fill.fill-lighten-2 {
    fill: #7986cb !important
}

.indigo-fill.fill-lighten-1 {
    fill: #5c6bc0 !important
}

.indigo-fill.fill-darken-1 {
    fill: #3949ab !important
}

.indigo-fill.fill-darken-2 {
    fill: #303f9f !important
}

.indigo-fill.fill-darken-3 {
    fill: #283593 !important
}

.indigo-fill.fill-darken-4 {
    fill: #1a237e !important
}

.indigo-fill.fill-accent-1 {
    fill: #8c9eff !important
}

.indigo-fill.fill-accent-2 {
    fill: #536dfe !important
}

.indigo-fill.fill-accent-3 {
    fill: #3d5afe !important
}

.indigo-fill.fill-accent-4 {
    fill: #304ffe !important
}