.footerContacts {
    margin: auto;
    align-items: center;
    max-width: 1170px;
}
.footerCont {
    justify-content: space-between;
    align-items: stretch;
    padding: 0.5rem 1rem;

}
.logotype{
    width: 100%;
    max-width: 250px;
    filter: invert(1);
}

@media (max-width:919px) {
    .infoBlock {
        justify-content: space-evenly;
        width: calc(100% - 100px);
    }
}

@media (max-width:767px) {
    .hr {
        display: none;
    }
}

@media (max-width:699px) {
    .infoBlock {
        flex-direction: column;
    }
}

@media (max-width:530px) {
    .protectionCont {
        display: flex;
        width: calc(100% - 50px);
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;

        .protection {
            margin: 15px 0;
        }
    }
}