.mord {
    background-color: #2196F3 !important
}

.mord.widgetcont-out>.ea-row>.booking-title>h2,
h5 {
    color: #dbb56e !important
}

.mord>.widgetcont-in>.container-date>.ea-input-all>div>.ea-input-write {
    left: 0;
    width: calc(100% - 35px);
}

.mord-fill {
    fill: #dbb56e !important
}

.mord.darken-4 {
    background-color: #ffffff !important
}

.mord-fill.fill-darken-1 {
    fill: #dbb56e !important
}

.mord.lighten-5 {
    background-color: #ffffff !important
}

.mord-text.text-lighten-1 {
    color: #bb9e60 !important
}

.mord-text {
    color: #bb9e60 !important
}

.mord.darken-3 {

    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    border-radius: 0;
    color: #ffffff;
    height: 42px;
    font-size: 13.5px;
    font-weight: bold;
    background-color: #dbb56e;
    background-image: -webkit-linear-gradient(bottom, #dbb56e, #dbb56e);
    background-image: linear-gradient(to top, #dbb56e, #dbb56e);
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.16);
    border-top-color: rgba(255, 255, 255, 0.16);
    border-top-width: 1px;
    box-shadow: 0 1px 3px 0 #00000000, 0 1px 0 0 #ffffff00 inset;
    text-shadow: 0 0 0 #00000000;
    white-space: normal;
}

.mord.darken-3:hover {

    background-color: #e3c68f;
    background-image: -webkit-linear-gradient(bottom, #e3c68f, #f0e4cf);
    background-image: linear-gradient(to top, #e3c68f, #f0e4cf);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-top-width: 1px;
    border-top-color: rgba(255, 255, 255, 0.16) !important;
}