.cont {
    width: calc(100% - 30px);
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 106px;
    background-color: mediumslateblue;
    justify-content: space-between;
    position: absolute;
    z-index: 999;
}
.contObject{
    width: 100%;
    max-width: 1400px;
    margin: auto;
}
.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    margin-top: 18px;
}

.logotype {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    padding: 15px 0 0 0;
}

.nav {
    display: flex;
    align-items: center;
    background-color: #fff;
}

.burgerBtn {
    width: 18px;
    height: 18px;
    position: relative;
    margin-left: 20px;
    cursor: pointer;

    .span {
        top: 8px
    }
}

.burgerBtn::after,
.burgerBtn::before,
.span {
    content: '';
    position: absolute;
    width: 18px;
    background-color: #000;
    height: 2px;
}

.burgerBtn::after {
    top: 0;
}

.burgerBtn::before {
    bottom: 0;


}

.adres {
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
    flex: 0 1 30%;

    img {
        max-width: 25px;
    }
}

@media (max-width:1000px) {

    .adres {
        
        color: #0000008c;
        text-align: center;
        margin-top: 0rem;
        flex: 0 1 55%;

        img {
            max-width: 25px;
        }

        p {
            width: 65%;
            font-size: 0.7rem;
        }
    }
}

@media (max-width:500px) {
    .contObject{
        flex-direction: row-reverse;
    }
    .menu {
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
    }

    .cont {
        width: 100%;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 106px;
        background-color: mediumslateblue;
        justify-content: space-between;
        position: absolute;
        z-index: 999;
    }

    .adres {
        max-width: 50%;
        font-size: 0.7rem;
        font-weight: bold;
        color: #000000;
        text-align: right;
        margin-top: 1rem;
    }
}