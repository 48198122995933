.hotelanapa {
    background-color: #ffc107 !important
}

.hotelanapa-text {
    color: #9ba4d0 !important
}

.hotelanapa.lighten-5 {
    background-color: #fff8e1 !important
}

.hotelanapa-text.text-lighten-5 {
    color: #fff8e1 !important
}

.hotelanapa.lighten-4 {
    background-color: #ffecb3 !important
}

.hotelanapa-text.text-lighten-4 {
    color: #ffecb3 !important
}

.hotelanapa.lighten-3 {
    background-color: #ffe082 !important
}

.hotelanapa-text.text-lighten-3 {
    color: #ffe082 !important
}

.hotelanapa.lighten-2 {
    background-color: #ffd54f !important
}

.hotelanapa-text.text-lighten-2 {
    color: #ffd54f !important
}

.hotelanapa.lighten-1 {
    background-color: #ffca28 !important
}

.hotelanapa-text.text-lighten-1 {
    color: #9ba4d0 !important
}

.hotelanapa.darken-1 {
    background-color: #ffb300 !important
}

.hotelanapa-text.text-darken-1 {
    color: #ffb300 !important
}

.hotelanapa.darken-2 {
    background-color: #ffa000 !important
}

.hotelanapa-text.text-darken-2 {
    color: #ffa000 !important
}

.hotelanapa.darken-3 {
    background-color: #ff8f00 !important;
    transition: .5s;
}

.hotelanapa.darken-3:hover {
    box-shadow: 0 0 6px 0px black;
    transition: .5s;
}

.hotelanapa-text.text-darken-3 {
    color: #ff8f00 !important
}

.hotelanapa.darken-4 {
    background-color: #fbc104 !important
}

.hotelanapa-text.text-darken-4 {
    color: #ff6f00 !important
}

.hotelanapa.accent-1 {
    background-color: #ffe57f !important
}

.hotelanapa-text.text-accent-1 {
    color: #ffe57f !important
}

.hotelanapa.accent-2 {
    background-color: #ffd740 !important
}

.hotelanapa-text.text-accent-2 {
    color: #ffd740 !important
}

.hotelanapa.accent-3 {
    background-color: #ffc400 !important
}

.hotelanapa-text.text-accent-3 {
    color: #ffc400 !important
}

.hotelanapa.accent-4 {
    background-color: #ffab00 !important
}

.hotelanapa-text.text-accent-4 {
    color: #ffab00 !important
}


.hotelanapa-fill {
    fill: #ffc107 !important
}

.hotelanapa-fill.fill-lighten-5 {
    fill: #fff8e1 !important
}

.hotelanapa-fill.fill-lighten-4 {
    fill: #ffecb3 !important
}

.hotelanapa-fill.fill-lighten-3 {
    fill: #ffe082 !important
}

.hotelanapa-fill.fill-lighten-2 {
    fill: #ffd54f !important
}

.hotelanapa-fill.fill-lighten-1 {
    fill: #ffca28 !important
}

.hotelanapa-fill.fill-darken-1 {
    fill: #ffb300 !important
}

.hotelanapa-fill.fill-darken-2 {
    fill: #ffa000 !important
}

.hotelanapa-fill.fill-darken-3 {
    fill: #ff8f00 !important
}

.hotelanapa-fill.fill-darken-4 {
    fill: #ff6f00 !important
}

.hotelanapa-fill.fill-accent-1 {
    fill: #ffe57f !important
}

.hotelanapa-fill.fill-accent-2 {
    fill: #ffd740 !important
}

.hotelanapa-fill.fill-accent-3 {
    fill: #ffc400 !important
}

.hotelanapa-fill.fill-accent-4 {
    fill: #ffab00 !important
}