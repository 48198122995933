.cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.contSocIcon{
    display: flex;
    a{
        flex: 0 1 30%;
    }
}
.img{
    box-shadow: 0 0 5px black;
    width: 35px;
    height: 35px;
    border-radius: 22%;
    margin: 3px;
    animation: sText 0.25s ease;
}
.img:hover{
    box-shadow:inset 0 0 5px black;  
    animation: sTextH 0.25s ease;
}
@keyframes sText {
    0% {
        box-shadow:inset 0 0 5px black;
    }
    10%{
        box-shadow:inset 0 0 4px black;
    }
    20% {
        box-shadow:inset 0 0 3px black;
    }
    30% {
        box-shadow:inset 0 0 2px black;
    }
    40% {
        box-shadow:inset 0 0 1px black;
    }
    48% {
        box-shadow:inset 0 0 1px black;
    }
    52% {
        box-shadow: 0 0 1px black;
    }
    60% {
        box-shadow:  0 0 1px black;
    }
    70% {
        box-shadow:  0 0 2px black;
    }
    80% {
        box-shadow:  0 0 3px black;
    }
    90% {
        box-shadow:  0 0 4px black;
    }
    100% {
        box-shadow:  0 0 5px black;
    }
}
@keyframes sTextH {
      0% {
        box-shadow: 0 0 5px black;
        // border-radius: 22%;
    }
    10%{
        box-shadow: 0 0 4px black;
        // border-radius: 23%;
    }
    20% {
        box-shadow: 0 0 3px black;
        // border-radius: 23%;
    }
    30% {
        box-shadow: 0 0 2px black;
        // border-radius: 24%;
    }
    40% {
        box-shadow: 0 0 1px black;
        // border-radius: 24%;

    }
    50% {
        box-shadow: 0 0 0;
        // border-radius: 25%;
    }
    60% {
        box-shadow: inset 0 0 1px black;
        // border-radius: 30%;
    }
    70% {
        box-shadow: inset 0 0 2px black;
        // border-radius: 35%;
    }
    80% {
        box-shadow: inset 0 0 3px black;
        // border-radius: 40%;
    }
    90% {
        box-shadow: inset 0 0 4px black;
        // border-radius: 45%;
    }
    100% {
        box-shadow: inset 0 0 5px black;
        // border-radius: 50%;
    }
}
@media screen and (max-width:900px) {
    .items{
        margin-top: 0rem;
    }
}

@media screen and (max-width:765px) {
    .items{
        margin-top: 2rem;
    }
}

