.deep-purple {
    background-color: #673ab7 !important
}

.deep-purple-text {
    color: #673ab7 !important
}

.deep-purple.lighten-5 {
    background-color: #ede7f6 !important
}

.deep-purple-text.text-lighten-5 {
    color: #ede7f6 !important
}

.deep-purple.lighten-4 {
    background-color: #d1c4e9 !important
}

.deep-purple-text.text-lighten-4 {
    color: #d1c4e9 !important
}

.deep-purple.lighten-3 {
    background-color: #b39ddb !important
}

.deep-purple-text.text-lighten-3 {
    color: #b39ddb !important
}

.deep-purple.lighten-2 {
    background-color: #9575cd !important
}

.deep-purple-text.text-lighten-2 {
    color: #9575cd !important
}

.deep-purple.lighten-1 {
    background-color: #7e57c2 !important
}
.deep-purple.lighten-1:hover {
    background-color: #714bb3 !important
}

.deep-purple-text.text-lighten-1 {
    color: #7e57c2 !important
}

.deep-purple.darken-1 {
    background-color: #4e4267 !important
}

.deep-purple-text.text-darken-1 {
    color: #5e35b1 !important
}

.deep-purple.darken-2 {
    background-color: #512da8 !important
}

.deep-purple-text.text-darken-2 {
    color: #512da8 !important
}

.deep-purple.darken-3 {
    background-color: #4527a0 !important
}

.deep-purple-text.text-darken-3 {
    color: #4527a0 !important
}

.deep-purple.darken-4 {
    background-color: #311b92 !important
}

.deep-purple-text.text-darken-4 {
    color: #311b92 !important
}

.deep-purple.accent-1 {
    background-color: #b388ff !important
}

.deep-purple-text.text-accent-1 {
    color: #b388ff !important
}

.deep-purple.accent-2 {
    background-color: #7c4dff !important
}

.deep-purple-text.text-accent-2 {
    color: #7c4dff !important
}

.deep-purple.accent-3 {
    background-color: #651fff !important
}

.deep-purple-text.text-accent-3 {
    color: #651fff !important
}

.deep-purple.accent-4 {
    background-color: #6200ea !important
}

.deep-purple-text.text-accent-4 {
    color: #6200ea !important
}



.deep-purple-fill {
    fill: #673ab7 !important
}

.deep-purple-fill.fill-lighten-5 {
    fill: #ede7f6 !important
}

.deep-purple-fill.fill-lighten-4 {
    // fill: #d1c4e9 !important 
    fill: #7b519c !important
}
.deep-purple-fill.fill-lighten-4:hover {
    fill: #d1c4e9 !important 
}

.deep-purple-fill.fill-lighten-3 {
    fill: #b39ddb !important
}

.deep-purple-fill.fill-lighten-2 {
    fill: #9575cd !important
}

.deep-purple-fill.fill-lighten-1 {
    fill: #7e57c2 !important
}

.deep-purple-fill.fill-darken-1 {
    fill: #5e35b1 !important
}

.deep-purple-fill.fill-darken-2 {
    fill: #512da8 !important
}

.deep-purple-fill.fill-darken-3 {
    fill: #4527a0 !important
}

.deep-purple-fill.fill-darken-4 {
    fill: #311b92 !important
}

.deep-purple-fill.fill-accent-1 {
    fill: #b388ff !important
}

.deep-purple-fill.fill-accent-2 {
    fill: #7c4dff !important
}

.deep-purple-fill.fill-accent-3 {
    fill: #651fff !important
}

.deep-purple-fill.fill-accent-4 {
    fill: #6200ea !important
}