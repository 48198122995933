.cardCont {
    // overflow: hidden;
    width: 100%;
    max-width: 250px;
    // height: 160px;

    background-color: #ffffff;
    border-radius: 9px;
    border: 1px solid #edd60500;
    box-shadow: #d4d4d4 0px 0px 10px 0px;
    transition: all 0.4s ease 0s;

    img {
        width: 80%;
        max-width: 220px;
        object-fit: contain;
        height: 130px;
        padding-top: 0.5rem;
    }
}

.cardCont:hover {
    border: 1px solid #edd505;
    box-shadow: #d4d4d4 0px 0px 10px 4px;
}

.hr {
    height: 30px;
    font-size: 0.9rem;
    text-align: center;
    color: #000000;

}

.textCont {

    p {
        text-align: center;
    }
}

@media screen and (max-width: 765px) {
    .cardCont {
        max-width: 320px;
    }
}