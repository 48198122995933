.black {
    background-color: #000 !important
}

.black.lighten-1 {
    background-color: #42A5F5 !important;
    transition: all 0.5s;
}
.black.lighten-1:hover {
    background-color: #1e7bc7 !important
}
.black.lighten-2 {
    background-color: #64B5F6 !important
}
.black.lighten-3 {
    background-color: #90CAF9 !important
}
.black.lighten-4 {
    background-color: #BBDEFB !important
}
.black.lighten-5 {
    background-color: #E3F2FD !important
}
.black.lighten-6 {
    background-color: #64b4f600 !important
}


.black.darken-1 {
    background-color: #5380a7 !important
}
.black.darken-1ap {
    background-color: #77a3caa9 !important
}
.black.darken-2 {
    background-color: #1976D2 !important
}
.black.darken-3 {
    background-color: #1565C0 !important
}
.black.darken-4 {
    background-color: #0D47A1 !important
}


.black.accent-1 {
    background-color: #82B1FF !important
}
.black.accent-2 {
    background-color: #448AFF !important
}
.black.accent-3 {
    background-color: #2979FF !important
}
.black.accent-4 {
    background-color: #2962FF !important
}



.black-text {
    color: #2196F3 !important
}
.black-text.text-lighten-1 {
    color: #42A5F5 !important
}
.black-text.text-lighten-2 {
    color: #64B5F6 !important
}
.black-text.text-lighten-3 {
    color: #90CAF9 !important
}
.black-text.text-lighten-4 {
    color: #BBDEFB !important
}
.black-text.text-lighten-5 {
    color: #E3F2FD !important
}
.black-text.text-lighten-5::after {
    background: #42A5F5 !important
}
.black-text.text-lighten-6 {
    color: #ffffff !important;
    transition: all 0.5s ease 0s;
}
.black-text.text-lighten-6:hover {
    color: #ffffff !important;
    text-shadow: 0 0 5px #ffffff;
}
.black-text.text-lighten-7 {
    color: #87919a !important
}


.black-text.text-darken-1 {
    color: #1E88E5 !important
}
.black-text.text-darken-2 {
    color: #1976D2 !important
}
.black-text.text-darken-3 {
    color: #1565C0 !important
}
.black-text.text-darken-4 {
    color: #0D47A1 !important
}


.black-text.text-accent-1 {
    color: #82B1FF !important
}
.black-text.text-accent-2 {
    color: #448AFF !important
}
.black-text.text-accent-3 {
    color: #2979FF !important
}
.black-text.text-accent-4 {
    color: #14358d !important
}



.black-fill {
    fill: #f39f21 !important
}
.black-fill.fill-lighten-1 {
    fill: #42A5F5 !important
}
.black-fill.fill-lighten-2 {
    fill: #64B5F6 !important
}
.black-fill.fill-lighten-3 {
    fill: #90CAF9 !important
}
.black-fill.fill-lighten-4 {
    fill: #ede318 !important;
    transition: all 0.3ms!important;
}

.black-fill.fill-lighten-4:hover {
    fill: #000000 !important
}
.black-fill.fill-lighten-5 {
    fill: #E3F2FD !important;
    box-shadow: 0 0 5px black;
}


.black-fill.fill-darken-1 {
    fill: #1E88E5 !important
}
.black-fill.fill-darken-2 {
    fill: #1976D2 !important
}
.black-fill.fill-darken-3 {
    fill: #1565C0 !important
}
.black-fill.fill-darken-4 {
    fill: #0D47A1 !important
}


.black-fill.fill-accent-1 {
    fill: #82B1FF !important
}
.black-fill.fill-accent-2 {
    fill: #448AFF !important
}
.black-fill.fill-accent-3 {
    fill: #2979FF !important
}