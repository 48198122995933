.deep-orange {
    background-color: #ff5722 !important
}

.deep-orange-text {
    color: #ff5722 !important
}

.deep-orange.lighten-5 {
    background-color: #fbe9e7 !important
}

.deep-orange-text.text-lighten-5 {
    color: #fbe9e7 !important
}

.deep-orange.lighten-4 {
    background-color: #ffccbc !important
}

.deep-orange-text.text-lighten-4 {
    color: #ffccbc !important
}

.deep-orange.lighten-3 {
    background-color: #ffab91 !important
}

.deep-orange-text.text-lighten-3 {
    color: #ffab91 !important
}

.deep-orange.lighten-2 {
    background-color: #ff8a65 !important
}

.deep-orange-text.text-lighten-2 {
    color: #ff8a65 !important
}

.deep-orange.lighten-1 {
    background-color: #ff7043 !important
}

.deep-orange-text.text-lighten-1 {
    color: #ff7043 !important
}

.deep-orange.darken-1 {
    background-color: #f4511e !important
}

.deep-orange-text.text-darken-1 {
    color: #f4511e !important
}

.deep-orange.darken-2 {
    background-color: #e64a19 !important
}

.deep-orange-text.text-darken-2 {
    color: #e64a19 !important
}

.deep-orange.darken-3 {
    background-color: #d84315 !important
}

.deep-orange-text.text-darken-3 {
    color: #d84315 !important
}

.deep-orange.darken-4 {
    background-color: #bf360c !important
}

.deep-orange-text.text-darken-4 {
    color: #bf360c !important
}

.deep-orange.accent-1 {
    background-color: #ff9e80 !important
}

.deep-orange-text.text-accent-1 {
    color: #ff9e80 !important
}

.deep-orange.accent-2 {
    background-color: #ff6e40 !important
}

.deep-orange-text.text-accent-2 {
    color: #ff6e40 !important
}

.deep-orange.accent-3 {
    background-color: #ff3d00 !important
}

.deep-orange-text.text-accent-3 {
    color: #ff3d00 !important
}

.deep-orange.accent-4 {
    background-color: #dd2c00 !important
}

.deep-orange-text.text-accent-4 {
    color: #dd2c00 !important
}


.deep-orange-fill {
    fill: #ff5722 !important
}

.deep-orange-fill.fill-lighten-5 {
    fill: #fbe9e7 !important
}

.deep-orange-fill.fill-lighten-4 {
    fill: #ffccbc !important
}

.deep-orange-fill.fill-lighten-3 {
    fill: #ffab91 !important
}

.deep-orange-fill.fill-lighten-2 {
    fill: #ff8a65 !important
}

.deep-orange-fill.fill-lighten-1 {
    fill: #ff7043 !important
}

.deep-orange-fill.fill-darken-1 {
    fill: #f4511e !important
}

.deep-orange-fill.fill-darken-2 {
    fill: #e64a19 !important
}

.deep-orange-fill.fill-darken-3 {
    fill: #d84315 !important
}

.deep-orange-fill.fill-darken-4 {
    fill: #bf360c !important
}

.deep-orange-fill.fill-accent-1 {
    fill: #ff9e80 !important
}

.deep-orange-fill.fill-accent-2 {
    fill: #ff6e40 !important
}

.deep-orange-fill.fill-accent-3 {
    fill: #ff3d00 !important
}

.deep-orange-fill.fill-accent-4 {
    fill: #dd2c00 !important
}