.amber {
    background-color: #ffc107 !important
}
.amber.lighten-1 {
    background-color: #ffca28 !important;
    transition: all 0.5s;
}
.amber.lighten-1:hover {
    background-color: #d1a623 !important;

}
.amber.lighten-2 {
    background-color: #ffd54f !important
}
.amber.lighten-3 {
    background-color: #ffe082 !important
}
.amber.lighten-4 {
    background-color: #ffecb3 !important
}
.amber.lighten-5 {
    background-color: #fff8e1 !important
}
.amber.lighten-6 {
    background-color: #fff8e100 !important
}


.amber.darken-1 {
    background-color: #ffb300 !important
}
.amber.darken-1ap {
    background-color: #ffb30093 !important
}

.amber.darken-2 {
    background-color: #ffa000 !important
}
.amber.darken-3 {
    background-color: #ff8f00 !important
}
.amber.darken-4 {
    background-color: #ff6f00 !important
}


.amber.accent-1 {
    background-color: #ffe57f !important
}
.amber.accent-2 {
    background-color: #ffd740 !important
}
.amber.accent-3 {
    background-color: #ffc400 !important
}
.amber.accent-4 {
    background-color: #ffab00 !important
}




.amber-text {
    color: #ff6f00 !important
}

.amber-text.text-lighten-1 {
    color: #ffca28 !important
}
.amber-text.text-lighten-2 {
    color: #ffd54f !important
}
.amber-text.text-lighten-3 {
    color: #ffe082 !important
}
.amber-text.text-lighten-4 {
    color: #ffecb3 !important
}
.amber-text.text-lighten-5 {
    color: #000000 !important;
    transition: all 0.5s;
}
.amber-text.text-lighten-5:hover {
    color: #ffffff !important;

}
.amber-text.text-lighten-5::after {
    color: #fad24f !important
}
.amber-text.text-lighten-6 {
    color: #ff6f00 !important;
    transition: all 0.5s ease 0s;
}
.amber-text.text-lighten-6:hover {
    color: #ff6f00 !important;
    text-shadow: 0 0 5px #ff6f00;
}
.amber-text.text-lighten-7 {
    color: #918d81 !important
}

.amber-text.text-darken-1 {
    color: #ffb300 !important
}

.amber-text.text-darken-2 {
    color: #ffa000 !important
}

.amber-text.text-darken-3 {
    color: #ff8f00 !important
}

.amber-text.text-darken-4 {
    color: #ff6f00 !important
}


.amber-text.text-accent-1 {
    color: #ffe57f !important
}

.amber-text.text-accent-2 {
    color: #ffd740 !important
}

.amber-text.text-accent-3 {
    color: #ffc400 !important
}

.amber-text.text-accent-4 {
    color: #ffab00 !important
}


.amber-fill {
    fill: #ffc107 !important
}

.amber-fill.fill-lighten-1 {
    fill: #ffca28 !important
}
.amber-fill.fill-lighten-2 {
    fill: #ffd54f !important
}
.amber-fill.fill-lighten-3 {
    fill: #ffe082 !important
}
.amber-fill.fill-lighten-4 {
    fill: #ffecb3 !important;
    transition: all 0.5s;
}
.amber-fill.fill-lighten-4:hover {
    fill: #fac422 !important
}
.amber-fill.fill-lighten-5 {
    fill: #fff8e1 !important;
    box-shadow: 0 0 5px black;
}

.amber-fill.fill-darken-1 {
    fill: #ffb300 !important
}

.amber-fill.fill-darken-2 {
    fill: #ffa000 !important
}

.amber-fill.fill-darken-3 {
    fill: #ff8f00 !important
}

.amber-fill.fill-darken-4 {
    fill: #ff6f00 !important
}


.amber-fill.fill-accent-1 {
    fill: #ffe57f !important
}
.amber-fill.fill-accent-2 {
    fill: #ffd740 !important
}
.amber-fill.fill-accent-3 {
    fill: #ffc400 !important
}
.amber-fill.fill-accent-4 {
    fill: #ffab00 !important
}