.cardCont {
    overflow: hidden;
    width: 100%;
    max-width: 230px;
    height: 260px;

    background-color: #ffffff;
    border-radius: 9px;
    border: 1px solid #edd60500;
    box-shadow: #d4d4d4 0px 0px 10px 0px;
    transition: all 0.4s ease 0s;

    img {
        width: 80%;
        max-width: 200px;
        height: 150px;
        object-fit: contain;
    }
}

.cardCont:hover {
    border: 1px solid #edd505;
    box-shadow: #d4d4d4 0px 0px 10px 4px;
}

.hr {
    height: 30px;
    font-size: 0.9rem;
    text-align: center;
    color: #000000;

}

.textCont {

    p {
        text-align: center;
        min-height: 70px;
        text-overflow: ellipsis;
        padding: 5px;
        overflow: hidden;
    }
}

.imgContPrew {
    width: 100%;
    height: 45vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: #dda93b;
    display: flex;
    align-items: center;

    h1 {
        color: #fff;
        // max-width: 590px;
        font-size: 2rem;
        text-shadow: 0 0 5px black;
    }
}

.imgContPrew::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000045;
}


@media screen and (max-width: 765px) {
    .cardCont {
        max-width: 160px;
        height: 230px;
    }

    .imgContPrew {
        height: 230px;

        h1 {
            font-size: 1.6rem;
        }
    }
}