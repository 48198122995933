

a{
  // text-decoration: none;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  user-select: none;
}

p {
  margin-bottom: 0
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  //   monospace;
}

.dFlex {
  display: flex;
  flex-wrap: wrap;
}

.fDirect {
  flex-direction: column;
}

.p05 {
  padding: 0.5rem !important;
}

.p06 {
  padding: 0.6rem !important;
}

.p07 {
  padding: 0.7rem !important;
}

.p08 {
  padding: 0.8rem !important;
}

.p09 {
  padding: 0.9rem !important;
}

.p1 {
  padding: 1rem !important;
}

.p2 {
  padding: 2rem !important;
}

.p3 {
  padding: 3rem !important;
}

.p4 {
  padding: 4rem !important;
}

.p5 {
  margin: 5rem !important;
}


.m05 {
  margin: 0.5rem !important;
}

.m06 {
  margin: 0.6rem !important;
}

.m07 {
  margin: 0.7rem !important;
}

.m08 {
  margin: 0.8rem !important;
}

.m09 {
  margin: 0.9rem !important;
}

.ml05 {
  margin-left: 0.5rem !important;
}

.ml06 {
  margin-left: 0.6rem !important;
}

.ml07 {
  margin-left: 0.7rem !important;
}

.ml08 {
  margin-left: 0.8rem !important;
}

.ml09 {
  margin-left: 0.9rem !important;
}

.mr05 {
  margin-right: 0.5rem !important;
}

.mr06 {
  margin-right: 0.6rem !important;
}

.mr07 {
  margin-right: 0.7rem !important;
}

.mr08 {
  margin-right: 0.8rem !important;
}

.mr09 {
  margin-right: 0.9rem !important;
}
.mt05 {
  margin-top: 0.5rem !important;
}

.mt06 {
  margin-top: 0.6rem !important;
}

.mt07 {
  margin-top: 0.7rem !important;
}

.mt08 {
  margin-top: 0.8rem !important;
}

.mt09 {
  margin-top: 0.9rem !important;
}
.mb05{
  margin-bottom: 0.5rem !important;
}
.mb06 {
  margin-bottom: 0.6rem !important;
}

.mb07 {
  margin-bottom: 0.7rem !important;
}

.mtb08 {
  margin-bottom: 0.8rem !important;
}

.mb09 {
  margin-bottom: 0.9rem !important;
}

.mA {
  margin: auto;
}
.m0{
  margin: 0rem !important;
}
.m1 {
  margin: 1rem !important;
}

.m2 {
  margin: 2rem !important;
}

.m3 {
  margin: 3rem !important;
}

.m4 {
  margin: 4rem !important;
}

.m5 {
  margin: 5rem !important;
}

.m6 {
  margin: 6rem !important;
}


.pl1 {
  padding-left: 1rem !important;
}

.pl2 {
  padding-left: 2rem !important;
}

.pl3 {
  padding-left: 3rem !important;
}

.pl4 {
  padding-left: 4rem !important;
}

.pr1 {
  padding-right: 1rem !important;
}

.pr2 {
  padding-right: 2rem !important;
}

.pr3 {
  padding-right: 3rem !important;
}

.pr4 {
  padding-right: 4rem !important;
}

.pt1 {
  padding-top: 1rem !important;
}

.pt2 {
  padding-top: 2rem !important;
}

.pt3 {
  padding-top: 3rem !important;
}

.pt4 {
  padding-top: 4rem !important;
}

.pb1 {
  padding-bottom: 1rem !important;
}

.pb2 {
  padding-bottom: 2rem !important;
}

.pb3 {
  padding-bottom: 3rem !important;
}

.pb4 {
  padding-bottom: 4rem !important;
}

.ml1 {
  margin-left: 1rem !important;
}

.ml2 {
  margin-left: 2rem !important;
}

.ml3 {
  margin-left: 3rem !important;
}

.ml4 {
  margin-left: 4rem !important;
}

.mr1 {
  margin-right: 1rem !important;
}

.mr2 {
  margin-right: 2rem !important;
}

.mr3 {
  margin-right: 3rem !important;
}

.mr4 {
  margin-right: 4rem !important;
}

.mt1 {
  margin-top: 1rem !important;
}

.mt2 {
  margin-top: 2rem !important;
}

.mt3 {
  margin-top: 3rem !important;
}

.mt4 {
  margin-top: 4rem !important;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.mb2 {
  margin-bottom: 2rem !important;
}

.mb3 {
  margin-bottom: 3rem !important;
}

.mb4 {
  margin-bottom: 4rem !important;
}

.wrap {
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: nowrap;
}

.justifyContentCenter {
  justify-content: center
}

.justifyContentFlexStart {
  justify-content: flex-start
}

.justifyContentFlexEnd {
  justify-content: flex-end
}

.justifyContentSpaceBetween {
  justify-content: space-between
}

.justifyContentSpaceAround {
  justify-content: space-around
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsStretch {
  align-items: stretch;
}

.cardContainer {
  border: 1px solid #a9a9a9;
}

.tACenter {
  text-align: center;
}