.orange {
    background-color: #ff9800 !important
}

.orange-text {
    color: #ff9800 !important
}

.orange.lighten-5 {
    background-color: #fff3e0 !important
}

.orange-text.text-lighten-5 {
    color: #fff3e0 !important
}

.orange.lighten-4 {
    background-color: #ffe0b2 !important
}

.orange-text.text-lighten-4 {
    color: #ffe0b2 !important
}

.orange.lighten-3 {
    background-color: #ffcc80 !important
}

.orange-text.text-lighten-3 {
    color: #ffcc80 !important
}

.orange.lighten-2 {
    background-color: #ffb74d !important
}

.orange-text.text-lighten-2 {
    color: #ffb74d !important
}

.orange.lighten-1 {
    background-color: #ffa726 !important
}

.orange-text.text-lighten-1 {
    color: #ffa726 !important
}

.orange.darken-1 {
    background-color: #fb8c00 !important
}

.orange-text.text-darken-1 {
    color: #fb8c00 !important
}

.orange.darken-2 {
    background-color: #f57c00 !important
}

.orange-text.text-darken-2 {
    color: #f57c00 !important
}

.orange.darken-3 {
    background-color: #ef6c00 !important
}

.orange-text.text-darken-3 {
    color: #ef6c00 !important
}

.orange.darken-4 {
    background-color: #e65100 !important
}

.orange-text.text-darken-4 {
    color: #e65100 !important
}

.orange.accent-1 {
    background-color: #ffd180 !important
}

.orange-text.text-accent-1 {
    color: #ffd180 !important
}

.orange.accent-2 {
    background-color: #ffab40 !important
}

.orange-text.text-accent-2 {
    color: #ffab40 !important
}

.orange.accent-3 {
    background-color: #ff9100 !important
}

.orange-text.text-accent-3 {
    color: #ff9100 !important
}

.orange.accent-4 {
    background-color: #ff6d00 !important
}

.orange-text.text-accent-4 {
    color: #ff6d00 !important
}


.orange-fill {
    fill: #ff9800 !important
}

.orange-fill.fill-lighten-5 {
    fill: #fff3e0 !important
}

.orange-fill.fill-lighten-4 {
    fill: #ffe0b2 !important
}

.orange-fill.fill-lighten-3 {
    fill: #ffcc80 !important
}

.orange-fill.fill-lighten-2 {
    fill: #ffb74d !important
}

.orange-fill.fill-lighten-1 {
    fill: #ffa726 !important
}

.orange-fill.fill-darken-1 {
    fill: #fb8c00 !important
}

.orange-fill.fill-darken-2 {
    fill: #f57c00 !important
}

.orange-fill.fill-darken-3 {
    fill: #ef6c00 !important
}

.orange-fill.fill-darken-4 {
    fill: #e65100 !important
}

.orange-fill.fill-accent-1 {
    fill: #ffd180 !important
}

.orange-fill.fill-accent-2 {
    fill: #ffab40 !important
}

.orange-fill.fill-accent-3 {
    fill: #ff9100 !important
}

.orange-fill.fill-accent-4 {
    fill: #ff6d00 !important
}
