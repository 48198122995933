.lime {
    background-color: #cddc39 !important
}

.lime-text {
    color: #cddc39 !important
}

.lime.lighten-6 {
    background-color: #f9fbe700 !important
}

.lime.lighten-5 {
    background-color: #f9fbe7 !important
}

.lime-text.text-lighten-5 {
    color: #ffffff !important
}

.lime.lighten-4 {
    background-color: #f0f4c3 !important
}

.lime-text.text-lighten-4 {
    color: #f0f4c3 !important
}

.lime.lighten-3 {
    background-color: #e6ee9c !important
}

.lime-text.text-lighten-3 {
    color: #e6ee9c !important
}

.lime.lighten-2 {
    background-color: #dce775 !important
}

.lime-text.text-lighten-2 {
    color: #dce775 !important
}

.lime.lighten-1 {
    background-color: #d0e420 !important;
    color: #4e531b;
}

.lime.lighten-1:hover {
    background-color: #d4e157 !important
}

.lime-text.text-lighten-1 {
    color: #d4e157 !important
}

.lime.darken-1 {
    background-color: #80853c !important
}

.lime-text.text-darken-1 {
    color: #4e531b !important
}

.lime.darken-2 {
    background-color: #afb42b !important
}

.lime-text.text-darken-2 {
    color: #afb42b !important
}

.lime.darken-3 {
    background-color: #9e9d24 !important
}

.lime-text.text-darken-3 {
    color: #9e9d24 !important
}

.lime.darken-4 {
    background-color: #827717 !important
}

.lime.darken-5 {
    background-color: #ccdf27 !important
}

.lime-text.text-darken-4 {
    color: #827717 !important
}

.lime.accent-1 {
    background-color: #f4ff81 !important
}

.lime-text.text-accent-1 {
    color: #f4ff81 !important
}

.lime.accent-2 {
    background-color: #eeff41 !important
}

.lime-text.text-accent-2 {
    color: #eeff41 !important
}

.lime.accent-3 {
    background-color: #c6ff00 !important
}

.lime-text.text-accent-3 {
    color: #c6ff00 !important
}

.lime.accent-4 {
    background-color: #aeea00 !important
}

.lime-text.text-accent-4 {
    color: #aeea00 !important
}


.lime-fill {
    fill: #cddc39 !important
}

.lime-fill.fill-lighten-5 {
    fill: #f9fbe7 !important
}


.lime-fill.fill-lighten-4 {
    fill: #f0f4c3 !important
}

.lime-fill.fill-lighten-3 {
    fill: #e6ee9c !important
}

.lime-fill.fill-lighten-2 {
    fill: #dce775 !important
}


.lime-fill.fill-lighten-1 {
    fill: #d4e157 !important
}

.lime-fill.fill-darken-1 {
    fill: #c0ca33 !important
}


.lime-fill.fill-darken-2 {
    fill: #afb42b !important
}


.lime-fill.fill-darken-3 {
    fill: #9e9d24 !important
}


.lime-fill.fill-darken-4 {
    fill: #827717 !important
}

.lime-fill.fill-accent-1 {
    fill: #f4ff81 !important
}

.lime-fill.fill-accent-2 {
    fill: #eeff41 !important
}

.lime-fill.fill-accent-3 {
    fill: #c6ff00 !important
}

.lime-fill.fill-accent-4 {
    fill: #aeea00 !important
}