* {
    outline: none;
    // height: 100%;
}

.slick-slide:focus {
    outline: none;
}

.slides {
    outline: none;
    margin-bottom: 50px;
}

/*слайдер услуги*/
.servicesMiniSlides {
    position: relative;

    .slick-dots {
        li {
            width: 24px !important;
            height: 0px !important;
            margin: 0 5px 0 0 !important;
        }
    }


    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 10;
    }

    .slick-arrow.slick-prev {
        left: 25px !important;
        background-image: url(https://icon-icons.com/downloadimage.php?id=103394&root=1496/SVG/&file=goprevious_103394.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .slick-arrow.slick-prev:before {
        content: '';
    }

    .slick-arrow.slick-next {
        right: 25px !important;
        background-image: url(https://icon-icons.com/downloadimage.php?id=103393&root=1496/SVG/&file=gonext_103393.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .slick-arrow.slick-next:before {
        content: '';
    }


    
    .slick-list {
            .slick-track {
                min-height: 480px;
                padding: 5px 0;
                .slick-slide {
                    div {
                        margin: auto;
                    }
                }
            }
        }
    }


/*Слайдер акции*/
.promotionsMiniSlides {
    .slick-arrow.slick-prev {
        left: 25px !important;
        background-image: url(https://icon-icons.com/downloadimage.php?id=103394&root=1496/SVG/&file=goprevious_103394.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }

    .slick-arrow.slick-prev:before {
        content: '';
    }

    .slick-arrow.slick-next {
        right: 25px !important;
        background-image: url(https://icon-icons.com/downloadimage.php?id=103393&root=1496/SVG/&file=gonext_103393.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .slick-arrow.slick-next:before {
        content: '';
    }

    .slick-list .slick-track {
        height: 230px;
        padding: 5px 0;

        .slick-slide div {
            .promo_contProm__2V5UA {
                display: block !important;
            }

            margin: 0 auto;
        }
    }
}

/*Слайдер комнаты*/

.roomMiniSlides {
    position: relative;

    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 10;
        // width: 40px;
        // height: 40px;
    }

    .slick-arrow.slick-prev {
        left: 25px !important;
        background-image: url(https://icon-icons.com/downloadimage.php?id=103394&root=1496/SVG/&file=goprevious_103394.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .slick-arrow.slick-prev:before {
        content: '';
    }

    .slick-arrow.slick-next {
        right: 25px !important;
        background-image: url(https://icon-icons.com/downloadimage.php?id=103393&root=1496/SVG/&file=gonext_103393.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .slick-arrow.slick-next:before {
        content: '';
    }

    .slick-list {
        .slick-track {
            .slick-slide {
                div {
                    margin: auto;
                }
            }
        }
    }
}

.menu {}

.menu.active {}

.blur {}

.menu__content {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
}

.menu__header {
    display: none;
}

.menuBody {
    display: block;
}

// .menuBody {}
@media (max-width:767px) {
    .menu {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        transform: translateX(-130%);
        transition: all 0.4s;
    }

    .menu.active {
        transform: translateX(0);
        z-index: 99999;
    }

    .blur {
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(2px);
        position: absolute;
    }

    .menu__content {
        width: 100%;
        height: 100%;
        background-color: #000000ed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        transition: all 0.6s;
    }

    .menu__header {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #fff;
        background-color: darkolivegreen;
        width: 100%;
        height: 70px;
    }
}

// @media (max-width:767px) {
//     .menuBody {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background-color: #000000a8;
//         padding: 100px 30px 30px 30px;
//         transition: left 0.3s ease 0s;
//         overflow: auto;
//     }
//     .menuBody::before {
//     content: '';
//     position:fixed;
//     width: 100%;
//     top:0;
//     left: 0;
//     height: 70px;
//     color: darkolivegreen;
//     }
// }

/* .slick-slide,.slick-active,.slick-current{
max-width: 450px!important;

.slick-next:before {
    content: '→';
}
} */