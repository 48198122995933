.yellow {
    background-color: #ffeb3b !important
}

.yellow.lighten-1 {
    background-color: #ffef5d !important;
    color:#5c5b1b
}
.yellow.lighten-1:hover {
    background-color: #dbc81f !important
}
.yellow.lighten-2 {
    background-color: #fff176 !important
}

.yellow.lighten-3 {
    background-color: #fff59d !important
}

.yellow.lighten-4 {
    background-color: #fff9c4 !important
}

.yellow.lighten-5 {
    background-color: #fffde7 !important
}
.yellow.lighten-6 {
    background-color: #fffde700 !important
}


.yellow.darken-1 {
    background-color: #b9a447 !important
}
.yellow.darken-2 {
    background-color: #fbc02d !important
}

.yellow.darken-3 {
    background-color: #f9a825 !important
}
.yellow.darken-4 {
    background-color: #f57f17 !important
}
.yellow.darken-5 {
    background-color: #ffdb4d !important
}


.yellow.accent-1 {
    background-color: #ffff8d !important
}
.yellow.accent-2 {
    background-color: #ff0 !important
}
.yellow.accent-3 {
    background-color: #ffea00 !important
}
.yellow.accent-4 {
    background-color: #ffd600 !important
}


.yellow-text {
    color: #ffeb3b !important
}

.yellow-text.text-lighten-1 {
    color: #ffee58 !important
}
.yellow-text.text-lighten-2 {
    color: #fff176 !important
}
.yellow-text.text-lighten-3 {
    color: #fff59d !important
}
.yellow-text.text-lighten-4 {
    color: #fff9c4 !important
}
.yellow-text.text-lighten-5 {
    color: #fffde7 !important
}
.yellow-text.text-lighten-6 {
    color: #000000 !important
}


.yellow-text.text-darken-1 {
    color: #685814 !important
}
.yellow-text.text-darken-2 {
    color: #fbc02d !important
}
.yellow-text.text-darken-3 {
    color: #f9a825 !important
}
.yellow-text.text-darken-4 {
    color: #f57f17 !important
}


.yellow-text.text-accent-1 {
    color: #ffff8d !important
}
.yellow-text.text-accent-2 {
    color: #ff0 !important
}
.yellow-text.text-accent-3 {
    color: #ffea00 !important
}
.yellow-text.text-accent-4 {
    color: #ffd600 !important
}



.yellow-fill {
    fill: #ffeb3b !important
}

.yellow-fill.fill-lighten-5 {
    fill: #fffde7 !important
}
.yellow-fill.fill-lighten-4 {
    fill: #fff9c4 !important
}
.yellow-fill.fill-lighten-3 {
    fill: #fff59d !important
}
.yellow-fill.fill-lighten-2 {
    fill: #fff176 !important
}
.yellow-fill.fill-lighten-1 {
    fill: #ffee58 !important
}


.yellow-fill.fill-darken-1 {
    fill: #fdd835 !important
}
.yellow-fill.fill-darken-2 {
    fill: #fbc02d !important
}
.yellow-fill.fill-darken-3 {
    fill: #f9a825 !important
}
.yellow-fill.fill-darken-4 {
    fill: #f57f17 !important
}



.yellow-fill.fill-accent-1 {
    fill: #ffff8d !important
}
.yellow-fill.fill-accent-2 {
    fill: #ff0 !important
}
.yellow-fill.fill-accent-3 {
    fill: #ffea00 !important
}
.yellow-fill.fill-accent-4 {
    fill: #ffd600 !important
}