.grey {
    background-color: #9e9e9e !important
}

.grey-text {
    color: #9e9e9e !important
}

.grey.lighten-5 {
    background-color: #fafafa !important
}

.grey-text.text-lighten-5 {
    color: #fafafa !important
}

.grey.lighten-4 {
    background-color: #f5f5f5 !important
}

.grey-text.text-lighten-4 {
    color: #f5f5f5 !important
}

.grey.lighten-3 {
    background-color: #eee !important
}

.grey-text.text-lighten-3 {
    color: #eee !important
}

.grey.lighten-2 {
    background-color: #e0e0e0 !important
}

.grey-text.text-lighten-2 {
    color: #e0e0e0 !important
}

.grey.lighten-1 {
    background-color: #bdbdbd !important
}

.grey-text.text-lighten-1 {
    color: #bdbdbd !important
}

.grey.darken-1 {
    background-color: #757575 !important
}

.grey-text.text-darken-1 {
    color: #757575 !important
}

.grey.darken-2 {
    background-color: #616161 !important
}

.grey-text.text-darken-2 {
    color: #616161 !important
}

.grey.darken-3 {
    background-color: #424242 !important
}

.grey-text.text-darken-3 {
    color: #424242 !important
}

.grey.darken-4 {
    background-color: #212121 !important
}

.grey-text.text-darken-4 {
    color: #212121 !important
}


.grey-fill {
    fill: #9e9e9e !important
}

.grey-fill.fill-lighten-5 {
    fill: #fafafa !important
}

.grey-fill.fill-lighten-4 {
    fill: #f5f5f5 !important
}

.grey-fill.fill-lighten-3 {
    fill: #eee !important
}

.grey-fill.fill-lighten-2 {
    fill: #e0e0e0 !important
}

.grey-fill.fill-lighten-1 {
    fill: #bdbdbd !important
}

.grey-fill.fill-darken-1 {
    fill: #757575 !important
}

.grey-fill.fill-darken-2 {
    fill: #616161 !important
}

.grey-fill.fill-darken-3 {
    fill: #424242 !important
}

.grey-fill.fill-darken-4 {
    fill: #212121 !important
}