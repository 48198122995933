.itemFooter{
    margin: 15px 0;
}
.socNet, .logFooter{
    flex: 0 2 30%;
}
.phoneFooter, .emailFooter{
    flex: 0 2 20%;
    min-width: 152px;
    text-align: center;
}

@media (max-width:919px) {
    .socNet{
        flex: 1 2 33%;
    }
}
@media (max-width:345px) {
    .socNet{
        max-width: 160px;
    }
}

