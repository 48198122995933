.cont {
    display: grid;
    justify-content: center;
    align-items: center;
    flex: 0 2 34%;
    width: 100%;
    margin-bottom: 0.5rem;

    .contSocIcon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.svgCont {
    margin: 0 5px;
}

@media (max-width:640px) {
    .cont {
        padding: 15px 0 0;
        flex: 1 1 100%;
        margin-bottom: 1rem;
    }
}