.blue-grey {
    background-color: #607d8b !important
}

.blue-grey-text {
    color: #607d8b !important
}

.blue-grey.lighten-5 {
    background-color: rgb(236, 239, 241) !important;
}

.blue-grey-text.text-lighten-5 {
    color: #eceff1 !important
}

.blue-grey.lighten-4 {
    background-color: #cfd8dc !important
}

.blue-grey-text.text-lighten-4 {
    color: #cfd8dc !important
}

.blue-grey.lighten-3 {
    background-color: #b0bec5 !important
}

.blue-grey-text.text-lighten-3 {
    color: #b0bec5 !important
}

.blue-grey.lighten-2 {
    background-color: #90a4ae !important
}

.blue-grey-text.text-lighten-2 {
    color: #90a4ae !important
}

.blue-grey.lighten-1 {
    background-color: #78909c !important
}

.blue-grey-text.text-lighten-1 {
    color: #78909c !important
}

.blue-grey.darken-1 {
    background-color: #546e7a !important
}

.blue-grey-text.text-darken-1 {
    color: #546e7a !important
}

.blue-grey.darken-2 {
    background-color: #455a64 !important
}

.blue-grey-text.text-darken-2 {
    color: #455a64 !important
}

.blue-grey.darken-3 {
    background-color: #37474f !important
}

.blue-grey-text.text-darken-3 {
    color: #37474f !important
}

.blue-grey.darken-4 {
    background-color: #263238 !important
}

.blue-grey-text.text-darken-4 {
    color: #263238 !important
}


.blue-grey-fill {
    fill: #607d8b !important
}

.blue-grey-fill.fill-lighten-5 {
    fill: #eceff1 !important
}

.blue-grey-fill.fill-lighten-4 {
    fill: #cfd8dc !important
}

.blue-grey-fill.fill-lighten-3 {
    fill: #b0bec5 !important
}

.blue-grey-fill.fill-lighten-2 {
    fill: #90a4ae !important
}

.blue-grey-fill.fill-lighten-1 {
    fill: #78909c !important
}

.blue-grey-fill.fill-darken-1 {
    fill: #546e7a !important
}

.blue-grey-fill.fill-darken-2 {
    fill: #455a64 !important
}

.blue-grey-fill.fill-darken-3 {
    fill: #37474f !important
}

.blue-grey-fill.fill-darken-4 {
    fill: #263238 !important
}