.pink {
    background-color: #e91e63 !important
}


.pink.lighten-1 {
    background-color: #ec407a !important
}
.pink.lighten-1:hover {
    background-color: #da4d7c !important
}
.pink.lighten-2 {
    background-color: #f06292 !important
}
.pink.lighten-3 {
    background-color: #f48fb1 !important
}
.pink.lighten-4 {
    background-color: #f8bbd0 !important
}
.pink.lighten-5 {
    background-color: #fce4ec !important
}
.pink.lighten-6 {
    background-color: #ec407900 !important
}


.pink.darken-1 {
    background-color: #b9134f !important
}
.pink.darken-2 {
    background-color: #c2185b !important
}
.pink.darken-3 {
    background-color: #ad1457 !important
}
.pink.darken-4 {
    background-color: #880e4f !important
}
.pink.darken-5 {
    background-color: #880e4f !important
}



.pink-text {
    color: #e91e63 !important
}


.pink-text.text-lighten-1 {
    color: #ec407a !important
}
.pink-text.text-lighten-2 {
    color: #f06292 !important
}
.pink-text.text-lighten-3 {
    color: #f48fb1 !important
}
.pink-text.text-lighten-4 {
    color: #f8bbd0 !important
}
.pink-text.text-lighten-5 {
    color: #fce4ec !important
}
.pink-text.text-lighten-6 {
    color: #ffffff !important
}












.pink-text.text-darken-1 {
    color: #aa0944 !important
}


.pink-text.text-darken-2 {
    color: #c2185b !important
}


.pink-text.text-darken-3 {
    color: #ad1457 !important
}


.pink-text.text-darken-4 {
    color: #880e4f !important
}

.pink.accent-1 {
    background-color: #ff80ab !important
}

.pink-text.text-accent-1 {
    color: #ff80ab !important
}

.pink.accent-2 {
    background-color: #ff4081 !important
}

.pink-text.text-accent-2 {
    color: #ff4081 !important
}

.pink.accent-3 {
    background-color: #f50057 !important
}

.pink-text.text-accent-3 {
    color: #f50057 !important
}

.pink.accent-4 {
    background-color: #c51162 !important
}

.pink-text.text-accent-4 {
    color: #c51162 !important
}


.pink-fill {
    fill: #e91e63 !important
}

.pink-fill.fill-lighten-5 {
    fill: #fce4ec !important
}

.pink-fill.fill-lighten-4 {
    // fill: #f8bbd0 !important #ff00b5
    fill: #ff00b5 !important
}
.pink-fill.fill-lighten-4:hover {
    fill: #f8bbd0 !important
}


.pink-fill.fill-lighten-3 {
    fill: #f48fb1 !important
}

.pink-fill.fill-lighten-2 {
    fill: #f06292 !important
}

.pink-fill.fill-lighten-1 {
    fill: #ec407a !important
}

.pink-fill.fill-darken-1 {
    fill: #d81b60 !important
}

.pink-fill.fill-darken-2 {
    fill: #c2185b !important
}


.pink-fill.fill-darken-3 {
    fill: #ad1457 !important
}

.pink-fill.fill-darken-4 {
    fill: #880e4f !important
}

.pink-fill.fill-accent-1 {
    fill: #ff80ab !important
}

.pink-fill.fill-accent-2 {
    fill: #ff4081 !important
}

.pink-fill.fill-accent-3 {
    fill: #f50057 !important
}

.pink-fill.fill-accent-4 {
    fill: #c51162 !important
}