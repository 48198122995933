.cont {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 0 2 34%;
    width: 100%;
    flex-direction: column;

    a {
        padding: 5px 0;
        transition: all 0.5s ease 0s;

        div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #000000;
            padding: 0 10px;
            font-weight: bold;
            font-size: 0.9rem;
        }
    }

    a:hover {
        text-shadow: 0 0 3px #ffffff;
    }
}

@media (max-width:1017px) {
    .cont {
        padding-top: 0rem;
        flex: 1 1 30%;
        max-width: 30%;
        

        a {
            margin: auto;
            padding: 0;
            div {
                padding: 0;
                img{
                    max-width: 25px;
                }
                p {
                    color: #000000;
                    font-weight: bold;
                    font-size: 0.8rem;
                }
            }
        }
    }
}
@media (max-width:500px) {
    .cont {
        // padding-top: 1rem;
        flex: 1 1 45%;
        max-width: 50%;
        font-size: 0.8rem;
        border-left: 1px solid #0000002b;
        height: 50px;
        margin-top: 1rem;
    }
}