.pontos-hotel {
    background-color: #23d6be !important
}

.pontos-hotel.lighten-1 {
    background-color: #FC9C38 !important
}

.pontos-hotel.lighten-2 {
    background-color: #35ecbd !important
}

.pontos-hotel.lighten-3 {
    background-color: #c8e7e3 !important
}

.pontos-hotel.lighten-4 {
    background-color: #efebfa !important
}

.pontos-hotel.lighten-5 {
    background-color: #f9fbfb !important;
    //background-color: rgba(35, 214, 190, .05)
}

.pontos-hotel.darken-1 {
    background-color: #757575 !important
}

.pontos-hotel.darken-2 {
    background-color: #616161 !important
}

.pontos-hotel.darken-3 {
    background-color: #FC9C38 !important;


}

.pontos-hotel.darken-3:hover {
    background-color: #ffc768 !important;


}

.pontos-hotel.darken-4 {
    background-color: #499fd7 !important
}

.pontos-hotel-text {
    color: #434545 !important;
}

.pontos-hotel-text.text-lighten-1 {
    color: #434545 !important;
}

.pontos-hotel-text.text-lighten-2 {
    color: #e0e0e0 !important;
}

.pontos-hotel-text.text-lighten-3 {
    color: #eee !important;
}

.pontos-hotel-text.text-lighten-4 {
    color: #f5f5f5 !important;
}

.pontos-hotel-text.text-lighten-5 {
    color: #fafafa !important;
}

.pontos-hotel-text.text-darken-1 {
    color: #757575 !important;
}


.pontos-hotel-text.text-darken-2 {
    color: #616161 !important;
}

.pontos-hotel-text.text-darken-3 {
    color: #424242 !important;
}


.pontos-hotel-text.text-darken-4 {
    color: #212121 !important;
}


.pontos-hotel-fill {
    fill: #9e9e9e !important
}

.pontos-hotel-fill.fill-lighten-5 {
    fill: #fafafa !important
}

.pontos-hotel-fill.fill-lighten-4 {
    fill: #f5f5f5 !important
}

.pontos-hotel-fill.fill-lighten-3 {
    fill: #eee !important
}

.pontos-hotel-fill.fill-lighten-2 {
    fill: #e0e0e0 !important
}

.pontos-hotel-fill.fill-lighten-1 {
    fill: #bdbdbd !important
}

.pontos-hotel-fill.fill-darken-1 {
    fill: #FC9C38 !important
}

.pontos-hotel-fill.fill-darken-2 {
    fill: #616161 !important
}

.pontos-hotel-fill.fill-darken-3 {
    fill: #00b59c !important
}

.pontos-hotel-fill.fill-darken-4 {
    fill: #3dbbaa !important
}