.contTabs {
    font-size: 1rem;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    text-align: left;
    flex-wrap: wrap;
}

.contTab {
    flex: 0 1 30%;
    min-width: 280px;
    font-size: 0.9rem;
}

@media screen and (max-width:765px) {
    .contTab {
        margin-bottom: 20px;
    }

}