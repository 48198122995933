.red {
    background-color: #F44336 !important
}

.red.lighten-1 {
    background-color: #EF5350 !important;
    transition: all ease 0.5s 0s;
}

.red.lighten-1:hover {
    background-color: #e0807f !important
}

.red.lighten-2 {
    background-color: #E57373 !important
}

.red.lighten-3 {
    background-color: #EF9A9A !important
}

.red.lighten-4 {
    background-color: #FFCDD2 !important
}

.red.lighten-5 {
    background-color: #FFEBEE !important
}

.red.lighten-6 {
    background-color: #e5737300 !important
}


.red.darken-1 {
    background-color: #cf5956 !important
}
.red.darken-2 {
    background-color: #D32F2F !important
}
.red.darken-3 {
    background-color: #C62828 !important
}
.red.darken-4 {
    background-color: #B71C1C !important
}
.red.darken-5 {
    background-color: #B71C1C !important
}


.red.accent-1 {
    background-color: #FF8A80 !important
}
.red.accent-2 {
    background-color: #FF5252 !important
}
.red.accent-3 {
    background-color: #FF1744 !important
}
.red.accent-4 {
    background-color: #D50000 !important
}


.red-text {
    color: #F44336 !important
}

.red-text.text-lighten-1 {
    color: #EF5350 !important
}

.red-text.text-lighten-2 {
    color: #E57373 !important
}

.red-text.text-lighten-3 {
    color: #EF9A9A !important
}

.red-text.text-lighten-4 {
    color: #FFCDD2 !important
}

.red-text.text-lighten-5 {
    color: #FFEBEE !important
}
.red-text.text-lighten-6 {
    color: #ffffff !important
}


.red-text.text-darken-1 {
    color: #E53935 !important
}
.red-text.text-darken-2 {
    color: #D32F2F !important
}
.red-text.text-darken-3 {
    color: #C62828 !important
}
.red-text.text-darken-4 {
    color: #B71C1C !important
}


.red-text.text-accent-1 {
    color: #FF8A80 !important
}

.red-text.text-accent-2 {
    color: #FF5252 !important
}

.red-text.text-accent-3 {
    color: #FF1744 !important
}

.red-text.text-accent-4 {
    color: #D50000 !important
}


.red-fill {
    fill: #F44336 !important
}
.red-fill.lighten-1 {
    fill: #EF5350 !important
}
.red-fill.lighten-2 {
    fill: #E57373 !important
}
.red-fill.lighten-3 {
    fill: #EF9A9A !important
}
.red-fill.lighten-4 {
    fill: #FFCDD2 !important
}
.red-fill.lighten-5 {
    fill: #FFEBEE !important
}

.red-fill.darken-1 {
    fill: #E53935 !important
}
.red-fill.darken-2 {
    fill: #D32F2F !important
}
.red-fill.darken-3 {
    fill: #C62828 !important
}
.red-fill.darken-4 {
    fill: #660101 !important
}




.red-fill.accent-1 {
    fill: #FF8A80 !important
}
.red-fill.accent-2 {
    fill: #FF5252 !important
}
.red-fill.accent-3 {
    fill: #FF1744 !important
}
.red-fill.accent-4 {
    fill: #D50000 !important
}