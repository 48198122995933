.fonImage{
    width: 100%;
    height: 480px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        text-align: center;
        color: #fff;
        h2{
            font-size: 2rem;
        }
        p{
            font-size: 1.05rem;
        }
    }
}
.fonImage::before {
    content: "";
    height: 480px;
    width: 100%;
    position: absolute;
    background: #0b0b0b;
    opacity: .6;
}
.breadCrumbsCont{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 1rem;
}
.galaryCont{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin:0 0 30px;
    div{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.btnsCont{
    justify-content: space-between;
}
@media screen and (max-width:375px){
    .btnsCont{
        flex-direction: column-reverse;
        align-content: center
    }
}
