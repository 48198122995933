.image{
    max-width: 150px;
    height: 150px;
    width: 100%;
    margin: 1rem;
    box-shadow: 0 0 7px 2px #00000073;
    border: 1px solid #00000000;
    border-radius: 10px;
    object-fit: cover;
}
.image:hover{
    box-shadow: 0 0 5px 2px #00000073;
    border: 1px solid #c9bb21;
}

@media screen and (max-width:765px) {
    .image{
        max-width: 140px;
        height: 140px;
    }
    
}