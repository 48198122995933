.demo-hotel {
    background-color: #ff006e !important
}

.demo-hotel.lighten-1 {
    background-color: #ff006e !important
}
.demo-hotel.lighten-1:hover {
    background-color: #f14e94 !important
}

.demo-hotel.lighten-2 {
    background-color: #ff60a5 !important
}

.demo-hotel.lighten-3 {
    background-color: #ff97c4 !important
}

.demo-hotel.lighten-4 {
    background-color: #ffc8e0 !important
}

.demo-hotel.lighten-5 {
    background-color: #ffe6f1 !important
}
.demo-hotel.lighten-6 {
    background-color: #ffe6f100 !important
}

.demo-hotel.darken-1 {
    background-color: #b4004e !important
}

.demo-hotel.darken-2 {
    background-color: #ae205e !important
}

.demo-hotel.darken-3 {
    background-color: #b54576 !important
}

.demo-hotel.darken-4 {
    background-color: #ac6886 !important
}

.demo-hotel.darken-5 {
    background-color: #b88b9e !important
}

/*bg end*/
/*text start*/
.demo-hotel-text {
    color: #fff !important;

}

.demo-hotel-text.text-lighten-1 {
    color: #ff006e !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-lighten-2 {
    color: #ff60a5 !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-lighten-3 {
    color: #ff97c4 !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-lighten-4 {
    color: #ffc8e0 !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-lighten-5 {
    color: #fff6fa !important;
    background-color: #ffffff00 !important;
}



.demo-hotel-text.text-darken-1 {
    color: #ac6886 !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-darken-2 {
    background-color: #ffffff00 !important;
    color: #b54576 !important;
}

.demo-hotel-text.text-darken-3 {
    color: #ae205e !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-darken-4 {
    color: #b4004e !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-darken-4:hover {
    color: #ff006e !important;
    background-color: #ffffff00 !important;
}

.demo-hotel-text.text-darken-5 {
    border-color: #ff006e !important;
}

.demo-hotel-text.text-darken-5:hover {
    color: #ff006e !important;
}

.demo-hotel-text.text-darken-5:before {
    border-color: #ff006e !important;
    color: #fff6fa !important
}

.demo-hotel-text.text-darken-5:hover:before {
    border-color: #fff6fa !important;
    color: #ff006e !important;
}

/*text end*/
/*svg start*/
.demo-hotel-fill {
    fill: #9e9e9e !important
}

.demo-hotel-fill.fill-lighten-5 {
    fill: #fafafa !important
}

.demo-hotel-fill.fill-lighten-4 {
    fill: #f5f5f5 !important
}

.demo-hotel-fill.fill-lighten-3 {
    fill: #eee !important
}

.demo-hotel-fill.fill-lighten-2 {
    fill: #e0e0e0 !important
}

.demo-hotel-fill.fill-lighten-1 {
    fill: #bdbdbd !important
}

.demo-hotel-fill.fill-darken-1 {
    fill: #35ecbd !important
}

.demo-hotel-fill.fill-darken-2 {
    fill: #616161 !important
}

.demo-hotel-fill.fill-darken-3 {
    fill: #00b59c !important
}

.demo-hotel-fill.fill-darken-4 {
    fill: #3dbbaa !important
}