@import "./color/amber.scss";

@import "./color/black.scss";
@import "./color/blue-grey.scss";
@import "./color/blue.scss";
@import "./color/brown.scss";

@import "./color/cyan.scss";

@import "./color/deep-orange.scss";
@import "./color/deep-purple.scss";

@import "./color/green.scss";
@import "./color/grey.scss";

@import "./color/indigo.scss";

@import "./color/light-blue.scss";
@import "./color/light-green.scss";
@import "./color/lime.scss";

@import "./color/orange.scss";

@import "./color/pink.scss";
@import "./color/purple.scss";

@import "./color/red.scss";

@import "./color/teal.scss";

@import "./color/white.scss";

@import "./color/yellow.scss";

/*hotel start*/

@import "./hotel/anapa-durso-tower.scss";
@import "./hotel/azure.scss";

@import "./hotel/demo-hotel.scss";

@import "./hotel/grand-priboi.scss";
@import "./hotel/greenpark.scss";

@import "./hotel/hotelanapa.scss";

@import "./hotel/lazurnyy.scss";

@import "./hotel/morskaya-dal.scss";

@import "./hotel/pontos-hotel.scss";

@import "./hotel/seabreezeresort.scss";

@import "./hotel/vogue.scss";

/*hotel end*/


.materialize-red {
    background-color: #e51c23 !important
}

.materialize-red.lighten-1 {
    background-color: #ea454b !important
}

.materialize-red.lighten-1:hover {
    background-color: #eb5f64 !important
}

.materialize-red.lighten-2 {
    background-color: #ee6e73 !important
}

.materialize-red.lighten-3 {
    background-color: #f3989b !important
}

.materialize-red.lighten-4 {
    background-color: #f8c1c3 !important
}

.materialize-red.lighten-5 {
    background-color: #fdeaeb !important
}

.materialize-red.lighten-6 {
    background-color: #e51c2300 !important
}

.materialize-red.darken-1 {
    background-color: #881014 !important
}

.materialize-red.darken-2 {
    background-color: #b9151b !important
}

.materialize-red.darken-3 {
    background-color: #a21318 !important
}

.materialize-red.darken-4 {
    background-color: #8b1014 !important
}

.materialize-red.darken-5 {
    background-color: #8b1014 !important
}

.materialize-red-text {
    color: #e51c23 !important
}

.materialize-red-text.text-lighten-1 {
    color: #ea454b !important
}

.materialize-red-text.text-lighten-2 {
    color: #ee6e73 !important
}

.materialize-red-text.text-lighten-3 {
    color: #f3989b !important
}

.materialize-red-text.text-lighten-4 {
    color: #f8c1c3 !important
}

.materialize-red-text.text-lighten-5 {
    color: #fdeaeb !important
}

.materialize-red-text.text-darken-1 {
    color: #d0181e !important
}

.materialize-red-text.text-darken-2 {
    color: #b9151b !important
}

.materialize-red-text.text-darken-3 {
    color: #a21318 !important
}

.materialize-red-text.text-darken-4 {
    color: #8b1014 !important
}

/* materialize-red end */

.transparent {
    background-color: rgba(0, 0, 0, 0) !important
}

.transparent-text {
    color: rgba(0, 0, 0, 0) !important
}