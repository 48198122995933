.button {}

.button::before,
.buttonPrev::before {
    line-height: 1.7rem;
    font-size: 1.3rem;
}

.button::before {
    content: '❱';
}

.button:hover {
    background-color: #000000;
}

.button,
.buttonPrev {
    width: 30px;
    height: 30px;
    text-align: center;
    background-color: rgba(53, 53, 53, 0.308);
    border-radius: 100%;
    z-index: 2;
}

.buttonPrev {}

.buttonPrev::before {
    content: '❰';
}

.buttonPrev:hover {
    background-color: #000000;
}

.shadow {
    box-shadow: #a9a9a994 0px 0px 5px 0px;
    border-radius: 10px;
}

.shadow:hover {
    box-shadow: #a9a9a9de 0px 0px 7px 0px;
    border-radius: 10px;
}

.buttonMore {
    max-width: 210px;
    width: 100%;
    background-color: #dda93b;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 3px black;
    border-radius: 15px;
    border: 1px solid #fdef8f;
}

.buttonMore:hover {
    background-color: #fdef8f;
    border: 1px solid #dda93b;
}

@media screen and (max-width:1200px) {
    .button {
        right: 30px;
        border-radius: 0;
    }

    .buttonPrev {
        left: 30px;
        border-radius: 0;
    }
}