.containerCard {
    max-width: 320px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 #a9a9a9;
    cursor: pointer;
    transition: 0.3s ease 0s;
    border: 2px solid #00000036;

}

.mapIcon {
    position: absolute;
    width: 100%;
    max-width: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 34.5px);
    z-index: 2;
}
.reviewsIcon {
    position: absolute;
    width: 100%;
    max-width: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    z-index: 2;
}

.containerCard:hover {
    box-shadow: 0 0 7px 2px #a9a9a9;

    .mapIcon {
        border-bottom-color: red;
        border-bottom-style: dashed;
        border-bottom-width: 3px;
    }
    .reviewsIcon {
        border-bottom-width: 0;
    }
}

.contImgs {}

.cardImg, .cardImgB {
    width: 100%;
    max-height: 230px;
    object-fit: cover;
}

.contImgs::after,.contImgsB::after {
    content: '';
    position: absolute;
    background-color: #00000034;
    width: 100%;
    height: 100%;
}


.aboutCardCont {
    h3 {
        padding: 0;
        font-size: 1.4rem;
        color: #000000;
    }

    p {
        font-size: 0.8rem;
        color: #0000006b;
    }
}

.containerCardT {
    max-width: 450px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 #a9a9a9;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    height: 185px;
}

.containerCardT:hover {
    box-shadow: 0 0 7px 2px #a9a9a9;
}

.cardImgT {
    max-width: 150px;
    height: 150px;
    object-fit: fill;

    img {
        max-width: 150px;
        height: 150px;
    }
}

.aboutCardContT {
    flex: 1 5 10%;

    h3 {
        padding: 0;
        font-size: 1.4rem;
        color: #000000;
    }

    p {
        font-size: 0.8rem;
        color: #0000006b;
    }
}

.contCenterCard {
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (min-width:550px) and (max-width:1050px) {
    .cardBlock {
        right: -5px;
        position: absolute;
        width: 30px;
        height: 50%;
        background-color: #d5c3f1;
        top: 25%;
        border-radius: 20px 0 0 20px;
        box-shadow: inset 2px 0px 10px 0px #000000ba;
        z-index: 2;
    }

    .mapIcon {
        display: none;
    }
    .reviewsIcon {
        display: none;
    }

    .contImgs::after {
        content: '';
        position: absolute;
        background-color: #00000000;
        width: 100%;
        height: 100%;
    }
    .contImgsB::after {
        content: '';
        position: absolute;
        background-color: #00000000;
        width: 100%;
        height: 100%;
    }

    .containerCard,
    .containerCardT {
        flex: 1 1 20%;
        max-width: 240px;
        height: 270px;
        border: 1px solid #56555530;
        border-bottom-color: #0000004f;
        border-bottom-style: solid;
        border-bottom-width: 8px;
        border-right-color: #00000069;
        border-right-style: solid;
        border-right-width: 4px;
        box-shadow: 0 6px 10px 5px #a9a9a994;
        border-radius: 40px;
        margin-bottom: 50px;
        position: relative;
        overflow: hidden;
        display: block;

        .contImgs,
        .contImgsB,
        .cardImgT {
            overflow: hidden;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin: 0.5rem;
            border-right-color: #00000080;
            border-right-style: solid;
            border-right-width: 4px;
            border-bottom-color: #00000080;
            border-bottom-style: solid;
            border-bottom-width: 4px;
            box-shadow: 4px 4px 8px 0px #000000cf;

        }

        .cardImgT {
            img {
                max-width: 65px;
                height: 65px;
                margin: 5px 0 0 5px;
            }

        }

        .contImgs {
            img {
                width: 100%;
                max-width: 100px;
            }
        }

        .aboutCardCont,
        .aboutCardContT {
            padding: 0.5rem !important;

            h3 {
                font-size: 0.9rem;
            }

            p {
                font-size: 0.7rem;
                max-width: 80%;
            }
        }

    }

    .containerCardT2 {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

@media screen and (max-width:500px) {
    .containerCardT {
        display: flex;
        flex-wrap: nowrap;
        max-width: 365px;
        border-radius: 100px 10px 10px 100px;

        p {
            max-width: 80%;
            width: 100%;
            padding: 0rem;
            font-size: 0.6rem;
            color: #000000;
        }

        h3 {
            font-size: 1rem;
        }
    }

    .cardImgT {
        margin: 0.5rem;
        border: 1px solid #0000004f;
        border-radius: 100px 0 0 100px;
        overflow: hidden;
        height: 170px;
        max-width: 140px;

        img {
            width: 90%;
            object-fit: contain;
            margin: auto;

        }
    }

    .containerCard {
        display: flex;
        flex-wrap: nowrap;
        max-width: 365px;
        border-radius: 100px 10px 10px 100px;



        p {
            max-width: 80%;
            width: 100%;
            padding: 0rem;
            font-size: 0.6rem;
            color: #000000;
        }

        h3 {
            font-size: 1rem;
        }
    }

    .contImgs {
        margin: 0.5rem;
        border: 1px solid #0000004f;
        border-radius: 100px 0 0 100px;
        overflow: hidden;

        img {
            width: 100%;
            object-fit: cover;

        }
    }
    .contImgsB {
        margin: 0.5rem;
        border: 1px solid #0000004f;
        border-radius: 100px 0 0 100px;
        overflow: hidden;

        img {
            width: 100%;
            object-fit: cover;

        }
    }
    .cardImg {
        width: 100%;
        max-height: 440px;
        object-fit: cover;
        object-position: left;
    }

    .cardImgB {
        width: 100%;
        max-height: 410px;
        object-fit: cover;
    }
    

    .contCenterCard {
        margin-left: 0;
        margin-right: 0;
    }

    .containerCardT2 {
        flex-direction: row-reverse
    }
}