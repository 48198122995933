.brown {
    background-color: #795548 !important
}

.brown-text {
    color: #795548 !important
}

.brown.lighten-5 {
    background-color: #efebe9 !important
}

.brown-text.text-lighten-5 {
    color: #efebe9 !important
}

.brown.lighten-4 {
    background-color: #d7ccc8 !important
}

.brown-text.text-lighten-4 {
    color: #d7ccc8 !important
}

.brown.lighten-3 {
    background-color: #bcaaa4 !important
}

.brown-text.text-lighten-3 {
    color: #bcaaa4 !important
}

.brown.lighten-2 {
    background-color: #a1887f !important
}

.brown-text.text-lighten-2 {
    color: #a1887f !important
}

.brown.lighten-1 {
    background-color: #8d6e63 !important
}

.brown-text.text-lighten-1 {
    color: #8d6e63 !important
}

.brown.darken-1 {
    background-color: #6d4c41 !important
}

.brown-text.text-darken-1 {
    color: #6d4c41 !important
}

.brown.darken-2 {
    background-color: #5d4037 !important
}

.brown-text.text-darken-2 {
    color: #5d4037 !important
}

.brown.darken-3 {
    background-color: #4e342e !important
}

.brown-text.text-darken-3 {
    color: #4e342e !important
}

.brown.darken-4 {
    background-color: #3e2723 !important
}

.brown-text.text-darken-4 {
    color: #3e2723 !important
}


.brown-fill {
    fill: #795548 !important
}

.brown-fill.fill-lighten-5 {
    fill: #efebe9 !important
}

.brown-fill.fill-lighten-4 {
    fill: #d7ccc8 !important
}

.brown-fill.fill-lighten-3 {
    fill: #bcaaa4 !important
}

.brown-fill.fill-lighten-2 {
    fill: #a1887f !important
}

.brown-fill.fill-lighten-1 {
    fill: #8d6e63 !important
}

.brown-fill.fill-darken-1 {
    fill: #6d4c41 !important
}

.brown-fill.fill-darken-2 {
    fill: #5d4037 !important
}

.brown-fill.fill-darken-3 {
    fill: #4e342e !important
}

.brown-fill.fill-darken-4 {
    fill: #3e2723 !important
}