.contServ {
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 250px;
    min-width: 220px;
    border-radius: 10px;
    border: 1px solid #a9a9a9;
    box-shadow: 0 0 5px #a9a9a9;
    overflow: hidden;
    align-content: space-between;
    flex-direction: column;
    align-items: center;

    .hr {
        background-color: #a9a9a9;
        width: 80%;
        margin:  0 auto 0.5rem auto;
        height: 1px;
    }

    .header {
        position: relative;
        width: 100%;
        max-height: min-content;

        .headerFon {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 150px;
            width: 100%;
            align-items: center;
            justify-content: center;

        }

        .titleHeader {
            font-size: 1.3rem;
            text-transform: uppercase;
            mix-blend-mode: difference;
            color: #ffffff;
            text-align: center;
            z-index: 2;

        }

        .titleHeader::after {
            font-size: 1.3rem;
        }
    }

    .header::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #00000045;
        z-index: 1;
    }

    .body {
        // flex-direction: column;
        // align-content: space-between;
        // align-items: center;
        // justify-content: center;        
        // align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;

        .titleBody {
            font-size: 1.1rem;
            text-transform: uppercase;
            padding: 0.2rem;
        }

        text-align: center;

        p {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            color: #7a7a7a;
            font-size: 0.9rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            line-height: 0.9rem;
            height: 100%;
            min-height: calc(0.9rem * 4.6);
            max-height: calc(0.9rem * 4.6);
        }
    }

    .footer {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        max-height: 60px;
    }
}

.container:hover {
    box-shadow: 0 0 10px 5px #a9a9a9;
}