.container {
    width: 100%;
    height: 500px;
    background-color: #1b1d32;
    box-shadow: #000000 0px 0px 5px 0px;
    a{
        color: #ffffffa2;
    }
}

.contactCardContainer {
    color: #ffffff69;
    width: 100%;
    max-width: 600px;
    border: 1px solid #ffffff42;
    border-radius: 33px;
    background-color: #21222e;
    box-shadow: 0 0 7px 0 #ffffff;
}

.cardContainer {
    border: 1px solid #ffffff42;
    border-radius: 23px;
    box-shadow: inset 0 0 10px 0 #ffffff;

    h3 {
        color: #ffffff;
        padding: 0px;
    }
}
.title{
    font-weight: bold;
    color: #ebe6e6;
    min-width: 100px;
}
.link{
    color: #ffffff69;
}

@media screen and (max-width:1070px) {
    .contactImg {
        display: none;
        ;
    }

    .container {
        width: 100%;
        height: auto;
        background-color: #1b1d32;
        box-shadow: #000000 0px 0px 5px 0px;
        padding-bottom: 1rem;
    }
    .cardContainer {
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
    .title{
        width: 100%;
    }
    .link{
        padding: 0.3rem;
    }
}
@media screen and (max-width:500px) {
    .contactCardContainer {
        border: none;
        border-radius: 0;
        background-color: #21222e00;
        box-shadow: none
    }
}