.purple {
    background-color: #9c27b0 !important
}

.purple-text {
    color: #9c27b0 !important
}

.purple.lighten-5 {
    background-color: #f3e5f5 !important
}

.purple-text.text-lighten-5 {
    color: #f3e5f5 !important
}

.purple.lighten-4 {
    background-color: #e1bee7 !important
}

.purple-text.text-lighten-4 {
    color: #e1bee7 !important
}

.purple.lighten-3 {
    background-color: #ce93d8 !important
}

.purple-text.text-lighten-3 {
    color: #ce93d8 !important
}

.purple.lighten-2 {
    background-color: #ba68c8 !important
}

.purple-text.text-lighten-2 {
    color: #ba68c8 !important
}

.purple.lighten-1 {
    background-color: #ab47bc !important
}
.purple.lighten-1:hover {
    background-color: #c212e0 !important
}

.purple.lighten-6{
    background-color: #aa47bc00 !important
}

.purple-text.text-lighten-1 {
    color: #ab47bc !important
}

.purple.darken-1 {
    background-color: #6e4f76 !important
}

.purple-text.text-darken-1 {
    color: #8e24aa !important
}

.purple.darken-2 {
    background-color: #7b1fa2 !important
}

.purple-text.text-darken-2 {
    color: #7b1fa2 !important
}

.purple.darken-3 {
    background-color: #6a1b9a !important
}

.purple-text.text-darken-3 {
    color: #6a1b9a !important
}

.purple.darken-4 {
    background-color: #4a148c !important
}

.purple-text.text-darken-4 {
    color: #4a148c !important
}

.purple.accent-1 {
    background-color: #ea80fc !important
}

.purple-text.text-accent-1 {
    color: #ea80fc !important
}

.purple.accent-2 {
    background-color: #e040fb !important
}

.purple-text.text-accent-2 {
    color: #e040fb !important
}

.purple.accent-3 {
    background-color: #d500f9 !important
}

.purple-text.text-accent-3 {
    color: #d500f9 !important
}

.purple.accent-4 {
    background-color: #a0f !important
}

.purple-text.text-accent-4 {
    color: #a0f !important
}


.purple-fill {
    fill: #9c27b0 !important
}

.purple-fill.fill-lighten-5 {
    fill: #f3e5f5 !important
}

.purple-fill.fill-lighten-4 {
    fill: #e1bee7 !important
}

.purple-fill.fill-lighten-3 {
    fill: #ce93d8 !important
}

.purple-fill.fill-lighten-2 {
    fill: #ba68c8 !important
}

.purple-fill.fill-lighten-1 {
    fill: #ab47bc !important
}

.purple-fill.fill-darken-1 {
    fill: #8e24aa !important
}

.purple-fill.fill-darken-2 {
    fill: #7b1fa2 !important
}

.purple-fill.fill-darken-3 {
    fill: #6a1b9a !important
}

.purple-fill.fill-darken-4 {
    fill: #4a148c !important
}

.purple-fill.fill-accent-1 {
    fill: #ea80fc !important
}

.purple-fill.fill-accent-2 {
    fill: #e040fb !important
}

.purple-fill.fill-accent-3 {
    fill: #d500f9 !important
}

.purple-fill.fill-accent-4 {
    fill: #a0f !important
}